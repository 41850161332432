import React from 'react'
import { Route } from 'react-router-dom'

import { useLayout } from '../../hooks/useLayout'
import { AppRoute } from '../../router'
import { Layout } from '../../UI'
import { Profile } from '../Profile'
import { Test } from './OnTest'
import { Repair } from './Repair'

export const Serviceman: React.FC = () => {
  const { mobile } = useLayout()
  return (
    <Layout {...mobile}>
      <Route path={AppRoute.ServicemanRepairs} component={Repair} />
      <Route path={AppRoute.ServicemanTest} exact component={Test} />
      <Route path={AppRoute.ServicemanProfile} exact component={Profile} />
    </Layout>
  )
}
