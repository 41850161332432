import React from 'react'
import { useParams } from 'react-router-dom'

import {
  IPostRepairsDTO,
  usePostRepairsMutation,
} from '../api/endpoints.axios/repairs'
import Chat from '../components/Chat/Chat'
import OldRepairList from '../features/repairSide/blocks/OldRepairList/OldRepairList'
import { RepairStatus } from '../models'
import { IRouterParams } from '../router'
import { useAuthService } from '../services/auth.service'
import { Button, Section } from '../UI'
import { useDrawer } from '../UI/Drawer/Drawer'

export const useRepairsDrawer = () => {
  const [createRepair, { isLoading }] = usePostRepairsMutation()

  const onSubmit = async (repair: IPostRepairsDTO) => {
    try {
      await createRepair(repair).unwrap()
    } catch (e) {
      alert(e.data.errorMessage)
    }
  }

  const { nodeId } = useParams<IRouterParams>()
  const { user } = useAuthService()
  const title = 'Ремонты'

  const content = (
    <Chat
      onSubmit={onSubmit}
      initialValues={{
        nodeId: Number(nodeId),
        status: RepairStatus.New,
        createdBy: user ? Number(user.id) : 0,
      }}
      loading={isLoading}
      name={'defect'}
      placeholder="Заведи ремонт"
    >
      <Section>
        <OldRepairList />
      </Section>
    </Chat>
  )

  const { drawer: repairDrawer, openDrawer } = useDrawer({ title, content })

  return (
    <>
      <Button
        title="Добавить ремонт"
        style={{ marginRight: 10 }}
        onClick={openDrawer}
      />
      {repairDrawer}
    </>
  )
}
