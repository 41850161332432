import React from 'react'
import { Field } from 'react-final-form'

import { useGetConsumersQuery } from '../../../api/endpoints.graphql/consumer'
import { useGetProvidersQuery } from '../../../api/endpoints.graphql/provider'
import { Button } from '../../../UI'

export const ReportForm = ({ handleSubmit, loading }) => {
  const { data: providers } = useGetProvidersQuery()
  const { data: consumers } = useGetConsumersQuery()
  return (
    <form className="Form" onSubmit={handleSubmit}>
      <Field component="select" className="Input" name={'consumer'}>
        {consumers?.map((consumer) => (
          <option value={consumer.id} key={consumer.id}>
            {consumer.name}
          </option>
        ))}
      </Field>

      <Field component="select" className="Input" name={'provider'}>
        {providers?.map((provider) => (
          <option value={provider.id} key={provider.id}>
            {provider.name}
          </option>
        ))}
      </Field>

      <Field
        render={() => (
          <div>
            <div>
              <label htmlFor="startDate"> sdfsdfsdfds </label>
            </div>
            <input
              id="startDate"
              type="date"
              className="Input"
              name={'startDate'}
            />
            <div>dsfsdfsdf</div>
          </div>
        )}
        name={'startDate'}
      />

      <Field
        component="input"
        type="date"
        className="Input"
        name={'startDate'}
      />
      <Field component="input" type="date" className="Input" name={'endDate'} />

      <label className="Input Switch">
        <Field component="input" type="checkbox" name={'summer'} hidden />
        <span /> Летняя
      </label>
      <br />
      <Button title={'Создать'} loading={loading} />
    </form>
  )
}
