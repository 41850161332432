// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewCommentForm-module__form-yBI14 {
  margin-bottom: var(--margin-m);
}`, "",{"version":3,"sources":["webpack://./src/features/repairSide/blocks/NewCommentForm/NewCommentForm.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF","sourcesContent":[".form {\n  margin-bottom: var(--margin-m);\n}"],"sourceRoot":""}]);
// Exports
export var form = `NewCommentForm-module__form-yBI14`;
export default ___CSS_LOADER_EXPORT___;
