import { format, subDays } from 'date-fns'

export const reportTableConfig = [
  { name: 'ID', value: 'id' },
  { name: 'Дата', value: 'createdAt', type: 'date' },
  { name: 'Аббонент', value: 'consumer.name' },
  { name: 'Поставщик', value: 'provider.name' },
  { name: 'Начало отчета', value: 'startDate' },
  { name: 'Конец отчета', value: 'endDate' },
]

const REPORT_PERIOD = {
  startDate: format(subDays(new Date(), 15), 'yyyy-MM-dd'),
  endDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
}

export const INITIAL_REPORT_VALUES = {
  consumer: 1,
  provider: 1,
  summer: false,
  ...REPORT_PERIOD,
}
