export interface Node {
  id: string
  name: string
  scheme: Scheme
  geu: string
  repairs: [Repair]
}

export interface Scheme {
  scheme: string
  chartHeating: string
  nodeId: number
  schemeActual: string
  note: string
  heatLoad1: number
  heatLoad2: number
  formulaWin: string
  formulaSum: string
  chartHws: number
  ths: number
}

export interface Repair {
  id: number
  defect: string
  comments: Comment[]
  status: number
  node: Node
  user: User
  createdAt: string
}

export interface Comment {
  id: number
  comment: string
  user: User
  createdAt: string
}

export interface Pipeline {
  id: number
  name: string
}
export interface User {
  id: number
  login: string
  email: string
  role: Role
  profile: Profile
}

export interface Role {
  role: string
}
export interface Profile {
  name: string
}

export interface Consumer {
  id: number
  name: string
  nodes?: [Node]
}

export interface Provider {
  id: number
  name: string
}

export interface Report {
  id: number
  name: string
  status: string
  consumer: Consumer
  provider: Provider
  startDate: string
  endDate: string
}

export interface Channel {
  pipeline: Pipeline
  pipelineId: number
  name: string
  ID: string
}

export enum RepairStatus {
  New = 1,
  Progress = 2,
  Done = 3,
  Tracked = 4,
  Accepted = 5,
}

export interface Location {
  nodeId: number
  access: string
  location: string
}
