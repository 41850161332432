// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chat {
  height: 100%;
  position: relative;
  box-sizing: border-box;
}
.Chat__Message {
  height: calc(100% - 50px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.Chat__Form {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgb(71.25, 71.25, 71.25);
  padding: 10px;
}
@media (max-width: 425px) {
  .Chat__Message {
    height: calc(100% - 61px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Chat/Chat.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,kBAAA;EACA,sBAAA;AADF;AAGE;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AADJ;AAIE;EACE,WAAA;EAEA,sBAAA;EACA,8CAAA;EACA,aAAA;AAHJ;AAME;EACE;IACE,yBAAA;EAJJ;AACF","sourcesContent":["@import '../../scss/const.scss';\n\n.Chat {\n  height: 100%;\n  position: relative;\n  box-sizing: border-box;\n\n  &__Message {\n    height: calc(100% - 50px);\n    overflow-y: scroll;\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__Form {\n    width: 100%;\n    // background: #777;\n    box-sizing: border-box;\n    border-top: 1px solid $secondary-color;\n    padding: 10px;\n  }\n\n  @media (max-width: 425px) {\n    &__Message {\n      height: calc(100% - 61px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
