import clsx from 'clsx'
import React from 'react'

import { Footer } from '../components/Footer/Footer'
import { Header, HeaderMobile } from '../components/Header'
import { MobileFooter } from '../components/mobile/Footer'
import { NodeList } from '../pages/Node/components/NodeList'
import RepairList from '../pages/Node/components/RepairList'
import { Tab } from '../UI'

export const useLayout = (classes?: string) => {
  return {
    init: {
      footer: <Footer />,
      header: <Header />,
      className: clsx([classes]),
    },
    mobile: {
      header: <HeaderMobile />,
      className: clsx(['Mobile', classes]),
      footer: <MobileFooter />,
    },
    node: {
      aside: (
        <Tab
          tabs={[
            { name: 'Ремонты', content: <RepairList />, id: 'repairsList' },
            { name: 'УКУТ', content: <NodeList />, id: 'all' },
          ]}
        />
      ),
    },
  }
}
