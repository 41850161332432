import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../img/logo.svg'
import { AppRoute, menu } from '../../router'
import { useAuthService } from '../../services/auth.service'
import { Button, List, ListItem } from '../../UI'
import Avatar from '../../UI/Avatar'
import * as styles from './Header.module.scss'

export function Header() {
  const { isLoggedIn, user, logout } = useAuthService()
  return (
    <div className={styles.header}>
      <img src={logo} className={clsx(styles.logo)} />
      <h3>ТЕПЛОБАЛАНС</h3>
      <List
        nav
        inline
        content={menu.map((route) => {
          return <ListItem key={route.path} to={route.path} text={route.name} />
        })}
      />
      <div className={styles.headerActions}>
        {isLoggedIn ? (
          <>
            <Avatar user={user} />{' '}
            <Button title="Выйти" icon="🚪" onClick={logout} />
          </>
        ) : (
          <Link to={AppRoute.Login}>Login</Link>
        )}
      </div>
    </div>
  )
}

export function HeaderMobile() {
  return (
    <div className={styles.header}>
      <img src={logo} className={clsx(styles.logo)} />
      <h3>ТЕПЛОБАЛАНС</h3>
    </div>
  )
}
