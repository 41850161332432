import React from 'react'

interface Props {
  children: JSX.Element[]
  inline?: boolean
}

const ListContent: React.FC<Props> = ({ inline, children }) => {
  return (
    <ul className={`List__content${inline ? ' List__content--inline' : ''}`}>
      {children}
    </ul>
  )
}

export default ListContent
