// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header-module__AppHeader-F6rey {
  grid-area: header;
  background: rgb(45.75, 45.75, 45.75);
}

.Mobile .Header-module__AppHeader-F6rey {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/UI/App/Header/Header.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,oCAAA;AADF;;AAKE;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;AAFJ","sourcesContent":["@import '../../../scss/const.scss';\n\n.AppHeader {\n  grid-area: header;\n  background: darken($secondary-color, 10%);\n}\n\n:global(.Mobile) {\n  .AppHeader {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var appHeader = `Header-module__AppHeader-F6rey`;
export default ___CSS_LOADER_EXPORT___;
