import React from 'react'
import { useParams } from 'react-router-dom'

import { useGetRepairByIdQuery } from '../../../../api/endpoints.axios/repairs'
import CommentsList from '../CommentList/CommentsList'
import { NewCommentForm } from '../NewCommentForm/NewCommentForm'
import { RepairItem } from '../RepairItem'

export function ActiveRepair() {
  const { repairId } = useParams<{ repairId: string }>()

  const { data: repair } = useGetRepairByIdQuery(
    {
      repairId,
    },
    { skip: !repairId }
  )

  if (!repair) {
    return null
  }

  return (
    <RepairItem
      title={{
        title: '🚨 Неисправность',
      }}
      repair={repair}
    >
      <CommentsList repair={repair} />
      <NewCommentForm repair={repair} />
    </RepairItem>
  )
}
