export enum AppRoute {
  Login = '/login',
  Node = '/node',
  NodeById = '/node/:nodeId',
  NodeByIdRepairById = '/node/:nodeId/repair/:repairId',
  Report = '/report',
  Repairs = '/repairs',
  RepairById = '/serviceman/repairs/:repairId',
  Serviceman = '/serviceman',
  ServicemanRepairs = '/serviceman/repairs',
  ServicemanTest = '/serviceman/test',
  ServicemanProfile = '/serviceman/profile',
  Staff = '/staff',
}
