import { format } from 'date-fns'
import { compose, partialRight, path, prop, replace } from 'ramda'

import { IIndication } from '../../../api/endpoints.axios/indication'
import { DateFormat } from '../../../const'
import { Channel } from '../../../models'
import { IValuesByDate } from '../../../pages/Report/types'
import { Store, store } from '../../../store'
import { TableHeader } from '../../../UI/Table/Table'
import { IChannelMap, IDataByDate } from '../types'

export const toFormatYyyyMmDd = partialRight(format, [DateFormat.yyyyMMdd])
const getDateObj = (str: string) => new Date(str)
const getPeriod = (store: Store) => path(['indication', 'period'], store)
const getPeriodFromStore = compose(getPeriod, store.getState)
const getStartDateFromPeriod = prop('startDate')
const getEndDateFromPeriod = prop('endDate')
const getFixedValue = (value: IIndication): string => value?.ValOut?.toFixed(2)
const EXCEL_EXT = 'xlsx'
export const addExtention = (fileName: string): string =>
  fileName + '.' + EXCEL_EXT
export const addCurrentDate = (name: string): string =>
  `${name} ${format(new Date(), DateFormat.ddMMyy)}`
const replaceSlashChar = replace('/', '-')
export const getNodeName = path<string>(['data', 'node', 'name'])

export const getCardNameWithExt = compose(
  addExtention,
  addCurrentDate,
  replaceSlashChar,
  getNodeName
)

export type ITableValue = { [colName: string]: string }

const getTableData = (data: IValuesByDate): ITableValue[] =>
  Object.keys(data).map((date) => ({
    date: new Date(date).toLocaleDateString(),
    ...data[date],
  }))

const getDataByDate = (indications: IIndication[], channelMap: IChannelMap) => {
  const data = indications.reduce((valuesByDate: IDataByDate, value) => {
    const pipelineId = channelMap[value.MeasureID]

    if (pipelineId) {
      valuesByDate[value.Timemark] = {
        ...valuesByDate[value.Timemark],
        [pipelineId]: getFixedValue(value),
      }
    }

    return valuesByDate
  }, {})

  return data
}

const sortedHeaders = (prev: Channel, next: Channel) => {
  return prev.pipeline.id - next.pipeline.id
}

export const header = (channels: Channel[]): TableHeader[] => {
  return (
    channels
      ?.filter((channel) => !!channel.pipeline.name)
      .sort(sortedHeaders)
      .map((channel) => ({
        name: channel.pipeline.name,
        value: channel.pipeline.name,
      })) || []
  )
}

export const addDateToHeader = (header: TableHeader[]) => [
  { name: 'Дата', value: 'date' },
  ...header,
]

export const createTable = compose(getTableData, getDataByDate)

export const getHeaderWithDate = compose(addDateToHeader, header)

export const getChannelIdToPipelineIdMap = (channels: Channel[]) =>
  channels.reduce((channelMap: IChannelMap, channel) => {
    if (channel.pipelineId) {
      channelMap[channel.ID] = channel.pipelineId
    }

    return channelMap
  }, {})

export const getChannelIdToNameMap = (channels: Channel[]) =>
  channels.reduce((channelMap: IChannelMap, channel) => {
    if (channel.pipeline.name) {
      channelMap[channel.ID] = channel.pipeline.name
    }

    return channelMap
  }, {})

export const getIds = (channels?: Channel[]) =>
  channels?.map((channel) => +channel.ID) || []

export const getStartDate = compose(getDateObj, getStartDateFromPeriod)

export const getEndDate = compose(getDateObj, getEndDateFromPeriod)
