import { yupResolver } from '@hookform/resolvers/yup'
import { propEq } from 'ramda'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useGetConsumersQuery } from '../../api/endpoints.graphql/consumer'
import { useGetProvidersQuery } from '../../api/endpoints.graphql/provider'
import {
  SelectField,
  SwitchField,
  TextField,
} from '../../components/useFormFields'
import { useLayout } from '../../hooks/useLayout'
import { useFileSaverService } from '../../services/FileSaver.service'
import { Button, Form, FormItem, Layout, Modal, Section } from '../../UI'
import { INITIAL_REPORT_VALUES } from './constants'
import { useCreateReport } from './hooks'
import * as styles from './Report.module.scss'

const loginSchema = yup.object({
  startDate: yup.string().required('Обязательно для заполненеия'),
  endDate: yup.string().required('Обязательно для заполненеия'),
  provider: yup.string().required('Обязательно для заполненеия'),
  consumer: yup.string().required('Обязательно для заполненеия'),
  summer: yup.boolean().required('Обязательно для заполненеия'),
})

export const ReportClient: React.FC = () => {
  const { data: providers } = useGetProvidersQuery()
  const { data: consumers } = useGetConsumersQuery()
  const { createReport, loading, report } = useCreateReport()
  const layout = useLayout(styles.report)
  const [show, setShow] = useState<boolean>(false)
  const { saveBlob } = useFileSaverService()

  const { handleSubmit, control, getValues } = useForm<{
    startDate: string
    endDate: string
    consumer: number
    provider: number
    summer: boolean
  }>({
    defaultValues: INITIAL_REPORT_VALUES,

    resolver: yupResolver(loginSchema),
  })

  const onFormSubmit = useCallback(
    async (form) => {
      setShow(true)
      await createReport(form)
    },
    [createReport]
  )

  const getReportName = () => {
    const { startDate, endDate, consumer, provider } = getValues()
    return `${consumers?.find(propEq('id', String(consumer)))?.name} ${
      providers?.find(propEq('id', String(provider)))?.name
    } (${startDate} ${endDate})`
  }

  return (
    <Layout empty>
      <Section>
        <h1>Параметры отчета</h1>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <SelectField
            control={control}
            name={'provider'}
            placeholder="Выберите поставщика"
            label={'Поставщик'}
            options={providers || []}
          />
          <SelectField
            control={control}
            name={'consumer'}
            placeholder="Выберите потребителя"
            label={'Потребитель'}
            options={consumers || []}
          />
          <TextField
            control={control}
            name={'startDate'}
            placeholder="Введите дату"
            label={'Дата начала отчета'}
            type="date"
          />
          <TextField
            control={control}
            name={'endDate'}
            placeholder="Введите дату"
            label={'Дата конца отчета'}
            type="date"
          />
          <SwitchField control={control} name={'summer'} label={'Лето'} />

          <FormItem>
            <Button title={'Создать'} loading={loading} />
          </FormItem>
        </Form>
      </Section>
      {show && (
        <Modal
          closeModal={() => setShow(false)}
          title={'Создание отчета'}
          onOk={() => saveBlob(report.zip, getReportName() + '.zip')}
          okText={'Загрузить'}
          okDisabled={!report.zip}
          cancelDisabled={report.status !== 'done'}
        >
          {loading && <p>Загружаем адреса</p>}
          {report.count && <p>Загружено узлов: {report.count}</p>}
          {report.name && <p>Создаем: {report.name}</p>}
          {report.counter > 0 && report.count && (
            <p>Создано карточек: {`${report.counter}/${report.count}`} </p>
          )}
          {report.status === 'done' && (
            <>
              <h2>Отчет создан</h2>
              <p>Можете загрузить отчет</p>
            </>
          )}
        </Modal>
      )}
    </Layout>
  )
}
