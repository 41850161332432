import { useParams } from 'react-router-dom'

import { useGetNodeByIdWithChannelsAndSchemaQuery } from '../api/endpoints.axios/nodes'
import { IRouterParams } from '../router'

export const useNodeService = () => {
  const { nodeId } = useParams<IRouterParams>()

  const { data: node, isFetching } = useGetNodeByIdWithChannelsAndSchemaQuery(
    {
      id: nodeId,
    },
    { skip: !nodeId }
  )

  return { isFetching, node }
}
