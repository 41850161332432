import { Consumer, Node, Scheme } from '../../models'
import { axiosApi } from '..'

export type IGetNodesWithChannelsRes = {
  id: number
  name: string
  channels: {
    id: number
    pipelineId: number
  }[]
}

export type IGetNodesWithChannelsArg = {
  provider: number
  consumer: number
}

export type IGetNodeByIdWithChannelsAndSchemaArgs = { id: string }
export type IGetNodeByIdWithChannelsAndSchemaRes = {
  id: number
  name: string
  contract: string
  channels: {
    id: number
    pipelineId: number
  }[]
  scheme: Scheme
  consumer: Consumer
}

export const repairsApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    getNodes: build.query<Node[], void>({
      query: () => ({
        url: '/nodes',
      }),
      transformResponse: (respose: Node[]) => respose || [],
      providesTags: ['Repairs'],
    }),
    getNodesWithChannels: build.query<IGetNodesWithChannelsRes, void>({
      query: () => ({
        url: '/node/channel',
      }),
      transformResponse: (respose: IGetNodesWithChannelsRes) => respose || [],
    }),
    getNodeByIdWithChannelsAndSchema: build.query<
      IGetNodeByIdWithChannelsAndSchemaRes,
      IGetNodeByIdWithChannelsAndSchemaArgs
    >({
      query: ({ id }) => ({
        url: `/node/${id}/channel`,
      }),
    }),
    updateNodeById: build.mutation<
      void,
      {
        id: number
        consumerId: string
        providerId: string
        contract: string
      }
    >({
      query: ({ id, ...data }) => ({
        url: `/node/${id}`,
        data: data,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetNodesQuery,
  useGetNodesWithChannelsQuery,
  useGetNodeByIdWithChannelsAndSchemaQuery,
  useUpdateNodeByIdMutation,
} = repairsApi
