import { configureStore } from '@reduxjs/toolkit'

import { axiosApi, grphqlApi } from '../api'
import auth, { InitialState as AuthState } from './reducers/authSlice'
import indication, {
  InitialState as IndicationState,
} from './reducers/indications'
import { InitialState as NodesState, nodesSlice } from './reducers/nodesSlice'

export interface Store {
  auth: AuthState
  nodes: NodesState
  indication: IndicationState
}
export const store = configureStore({
  reducer: {
    auth,
    nodes: nodesSlice.reducer,
    indication,
    [grphqlApi.reducerPath]: grphqlApi.reducer,
    [axiosApi.reducerPath]: axiosApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(grphqlApi.middleware)
      .concat(axiosApi.middleware),
})

export type RootStore = ReturnType<typeof store.getState>
