import { formatDistance } from 'date-fns'
import {
  compose,
  curry,
  equals,
  ifElse,
  join,
  partial,
  path,
  prop,
  slice,
} from 'ramda'

import { Repair, RepairStatus } from '../../../../models'

export const getDefect = prop('defect')
const getUserProfileName = partial<string>(path, [['user', 'profile', 'name']])
const getCretedDate = prop<string>('createdAt')

const formateCreatedDate = (date: string) =>
  formatDistance(date ? new Date(date) : new Date(), new Date())

const getDate = compose(formateCreatedDate, getCretedDate)
const addIcon = (title: string) => `🛠  ${title}`

const getUserNameAndDate: (repair: Repair) => string[] = (repair) => [
  getUserProfileName(repair),
  getDate(repair),
]

export const getTitle: (repair: Repair) => string = compose(
  join(', '),
  getUserNameAndDate
)

export const getTitleWithIcon: (repair: Repair) => string = compose(
  addIcon,
  getTitle
)

const getRepairStatus = prop('status')
const isRepairStatus = curry((statusId, status) => equals(statusId, status))
const isNewRepair = compose(isRepairStatus(RepairStatus.New), getRepairStatus)

const isAcceptedRepair = compose(
  isRepairStatus(RepairStatus.Done),
  getRepairStatus
)

const getNewRepairActionButtons = () => (actions) => slice(1, 2, actions)
const getProgressRepairActionButtons = () => (actions) => slice(0, 2, actions)
const getDoneRepairActionButtons = () => (actions) => slice(2, 3, actions)

const ifAcceptedRepair = ifElse(
  isAcceptedRepair,
  getDoneRepairActionButtons,
  getProgressRepairActionButtons
)

export const getActions = ifElse(
  isNewRepair,
  getNewRepairActionButtons,
  ifAcceptedRepair
)
