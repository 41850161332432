import { prop } from 'ramda'
import React from 'react'

import { useGetNodesQuery } from '../../../api/endpoints.graphql/node'
import { List, ListItem } from '../../../UI/List'

const getId = prop('id')
const getName = prop('name')

export const NodeList: React.FC = () => {
  const { data: nodes, isFetching } = useGetNodesQuery()

  return (
    <List
      filter="title"
      loading={isFetching}
      content={
        nodes?.map((node) => {
          return (
            <ListItem
              key={getId(node)}
              to={'/node/' + getId(node)}
              title={getName(node)}
            />
          )
        }) || []
      }
    />
  )
}
