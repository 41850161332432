// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tab {
  height: calc(100vh - 100px);
}
.Tab__Nav {
  display: flex;
  justify-content: space-around;
  position: relative;
  line-height: 50px;
  width: 100%;
}
.Tab__Nav .Tab__Cursor {
  height: 2px;
  position: absolute;
  bottom: 0;
  background-color: orange;
  transition: 0.4s;
}
.Tab__Content {
  height: calc(100% - 25px);
}
.Tab__Button {
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 10px;
  text-align: center;
  width: 100%;
}
.Tab__Button:hover {
  color: orange;
}`, "",{"version":3,"sources":["webpack://./src/UI/Tab/Tab.scss","webpack://./src/scss/const.scss"],"names":[],"mappings":"AAEA;EAIE,2BAAA;AAJF;AAKE;EACE,aAAA;EACA,6BAAA;EACA,kBAAA;EACA,iBCVS;EDWT,WAAA;AAHJ;AAKI;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,wBAAA;EACA,gBAAA;AAHN;AAOE;EACE,yBAAA;AALJ;AAQE;EACE,yBAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;AANJ;AAOI;EACE,aAAA;AALN","sourcesContent":["@import '../../scss/const.scss';\n\n.Tab {\n  // display: flex;\n  // align-items: center;\n  // padding: 0 5px;\n  height: calc(100vh - 100px);\n  &__Nav {\n    display: flex;\n    justify-content: space-around;\n    position: relative;\n    line-height: $min-height;\n    width: 100%;\n\n    .Tab__Cursor {\n      height: 2px;\n      position: absolute;\n      bottom: 0;\n      background-color: orange;\n      transition: 0.4s;\n    }\n  }\n\n  &__Content {\n    height: calc(100% - 25px);\n  }\n\n  &__Button {\n    text-transform: uppercase;\n    cursor: pointer;\n    margin: 0 10px;\n    text-align: center;\n    width: 100%;\n    &:hover {\n      color: orange;\n    }\n  }\n}\n","$theme: dark;\n$min-height: 50px;\n$title-height: 50px;\n\n$base-color: #212121;\n$active-color: lighten($base-color, 10%);\n$hover-color: lighten($base-color, 20%);\n\n$secondary-color: lighten($base-color, 15%);\n\n$accent-color: lighten($base-color, 15%);\n\n// $red: #c6538c;\n$red: #e12526;\n$font-color: #dadada;\n$font-color--active: darken($font-color, 10%);\n$font-color--hover: darken($font-color, 5%);\n//-------------\n// $base-color: #fff;\n// $active-color: #F4F5F7;\n// $hover-color: #EBECF0;\n\n// $secondary-color: rgb(244, 245, 247);\n// $accent-color: darken($base-color, 15%);\n\n// $font-color: #172B4D;\n// $font-color--active: #0053cc;\n// $font-color--hover: #172B4D;\n//--------\n$bckClr: $base-color;\n\n$footer-height: 22px;\n\n$mobile: 'max-width: 425px';\n$mobileMenu-height: 50px;\n$border: 1px solid $active-color;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
