// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Aside {
  border-right: 1px solid rgb(71.25, 71.25, 71.25);
  max-height: calc(100vh - 50px - 22px);
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-area: aside;
}
@media (max-width: 425px) {
  .Aside {
    flex-direction: row;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    height: 60px;
  }
}`, "",{"version":3,"sources":["webpack://./src/UI/App/Aside/Aside.scss"],"names":[],"mappings":"AAEA;EAII,gDAAA;EAcA,qCAAA;EACA,oBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AAjBJ;AAmBI;EAzBJ;IA0BQ,mBAAA;IACA,eAAA;IACA,SAAA;IACA,WAAA;IACA,YAAA;IACA,YAAA;EAhBN;AACF","sourcesContent":["@import '../../../scss/const.scss';\n\n.Aside {\n    // position: fixed;\n    // width: 150px;\n    // height: 100vh;\n    border-right: 1px solid $secondary-color;\n    // top: 0;\n    // padding: 10px;\n\n    // &+.Header {\n    //     margin-left: 150px;\n    //     &+.Content {\n    //         margin-top: 40px;\n    //         margin-left: 150px;\n    //     }\n    // }\n    // display: flex;\n    // flex-direction: column;\n    // justify-content: space-between;\n    max-height: calc(100vh - 50px - 22px);\n    grid-column-start: 1;\n    grid-column-end: 2;\n    grid-row-start: 1;\n    grid-row-end: 3;\n    grid-area: aside;\n\n    @media (max-width: 425px) {\n        flex-direction: row;\n        position: fixed;\n        bottom: 0;\n        width: 100%;\n        z-index: 100;\n        height: 60px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
