// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Node-module__RightPanel-AUeHM {
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 350px;
  overflow: auto;
  border-left: 1px solid #474747;
  height: calc(100% - 50px);
}

.Node-module__ContentWrapper-WClGM {
  padding-right: 350px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Node/Node.module.scss"],"names":[],"mappings":"AAIA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;EACA,8BAAA;EACA,yBAAA;AAHF;;AAMA;EACE,oBAAA;AAHF","sourcesContent":["@import '../../scss/const.scss';\n\n$right-panel-width: 350;\n\n.RightPanel {\n  position: absolute;\n  right: 0;\n  width: 100%;\n  max-width: #{$right-panel-width}px;\n  overflow: auto;\n  border-left: 1px solid #474747;\n  height: calc(100% - $title-height);\n}\n\n.ContentWrapper {\n  padding-right: #{$right-panel-width}px;\n}\n"],"sourceRoot":""}]);
// Exports
export var rightPanel = `Node-module__RightPanel-AUeHM`;
export var contentWrapper = `Node-module__ContentWrapper-WClGM`;
export default ___CSS_LOADER_EXPORT___;
