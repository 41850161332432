import React from 'react'
import { useController, UseControllerProps } from 'react-hook-form'

import { FormItem } from '../../UI'

interface ISelectProps extends UseControllerProps {
  placeholder: string
  label: string
  options: {
    id: number | string
    name: string
  }[]
}

export const SwitchField: React.FC<ISelectProps> = (props) => {
  const {
    field: { value, ...field },
    fieldState,
  } = useController(props)

  return (
    <FormItem hint={fieldState.error?.message} label={props.label}>
      <label className="Input Switch">
        <input
          {...field}
          hidden
          checked={value}
          type={'checkbox'}
          className="Input"
        />
        <span />
      </label>
    </FormItem>
  )
}
