import React from 'react'
import { Route, useParams } from 'react-router-dom'

import { useGetRepairsByNodeIdQuery } from '../../../../api/endpoints.axios/repairs'
import { RepairStatus } from '../../../../models'
import { AppRoute, IRouterParams } from '../../../../router'
import { ActiveRepair } from '../ActiveRepair'
import { RepairItem } from '../RepairItem'

const OldRepairList: React.FC = () => {
  const { nodeId } = useParams<IRouterParams>()
  const { data: oldRepairList } = useGetRepairsByNodeIdQuery({ nodeId })

  return (
    <>
      {oldRepairList?.map((repair) =>
        repair.status === RepairStatus.Progress ? (
          <Route path={AppRoute.NodeByIdRepairById}>
            <ActiveRepair />
          </Route>
        ) : (
          <RepairItem repair={repair} />
        )
      )}
    </>
  )
}

export default OldRepairList
