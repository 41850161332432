import React, { useMemo, useState } from 'react'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import {
  IGetNodesWithChannelsRes,
  useUpdateNodeByIdMutation,
} from '../api/endpoints.axios/nodes'
import { useGetConsumersQuery } from '../api/endpoints.graphql/consumer'
import { useGetProvidersQuery } from '../api/endpoints.graphql/provider'
import {
  SelectField,
  TextFieldMemo as TextField,
} from '../components/useFormFields'
import { Button, Form, Modal } from '../UI'

export interface INodeFormState {
  id: number
  contract: string
  consumerId: string
  providerId: string
}

export function useNodeEditModal(node: IGetNodesWithChannelsRes) {
  const [isOpen, setOpen] = useState(false)
  const onClick = () => setOpen(true)
  const toggleOpen = () => setOpen(!isOpen)
  const { data: provider } = useGetProvidersQuery()
  const { data: consumer } = useGetConsumersQuery()

  const conumerWithEmptyValue = useMemo(
    () => (consumer ? [...consumer, { id: 0, name: 'нет' }] : []),
    [consumer]
  )

  const [updateNode, { isLoading }] = useUpdateNodeByIdMutation()

  const { handleSubmit, control } = useForm<INodeFormState>({
    values: {
      id: node?.id,
      contract: node?.contract,
      consumerId: node?.consumer?.id,
      providerId: node?.provider?.id,
    },
  })

  const submitForm = useCallback(
    async (values: INodeFormState) => {
      try {
        await updateNode(values).unwrap()
        toggleOpen()
      } catch (e) {
        console.log(e)
      }
    },
    [toggleOpen, updateNode]
  )

  return (
    <>
      <Button title="Edit" onClick={onClick} />
      {isOpen && (
        <Modal
          okLoading={isLoading}
          onOk={handleSubmit(submitForm)}
          okText={'Сохранить'}
          closeModal={toggleOpen}
          title={node.name}
        >
          <Form>
            <TextField
              name="contract"
              label="Договор"
              placeholder=""
              control={control}
            />
            <SelectField
              control={control}
              name="consumerId"
              label="Потребитель"
              placeholder=""
              options={conumerWithEmptyValue}
            />
            <SelectField
              control={control}
              name="providerId"
              label="Поставщик"
              placeholder=""
              options={provider}
            />
          </Form>
        </Modal>
      )}
    </>
  )
}
