// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Comment-module__CommentList-dAZyZ {
  -webkit-mask-image: linear-gradient(transparent, rgba(0, 0, 255, 0.8) 30px, rgba(0, 0, 255, 0.8) calc(100% - 30px), transparent 100%);
  overflow-y: scroll;
  position: relative;
  flex-grow: 1;
  padding: 20px 0;
}

.Comment-module__MessgaeDate-Y7kP_ {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.Comment-module__Label-kn5cs {
  text-align: center;
  padding: 2px 10px;
  background: rgb(68, 68, 68);
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/features/repairSide/blocks/CommentList/Comment.module.scss"],"names":[],"mappings":"AACA;EACE,qIAAA;EAOA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AANF;;AASA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AANF;;AASA;EACE,kBAAA;EACA,iBAAA;EACA,2BAAA;EACA,kBAAA;AANF","sourcesContent":["\n.CommentList {\n  -webkit-mask-image: linear-gradient(\n    transparent,\n    rgba(0, 0, 255, 0.8) 30px,\n    rgba(0, 0, 255, 0.8) calc(100% - 30px),\n    transparent 100%\n  );\n\n  overflow-y: scroll;\n  position: relative;\n  flex-grow: 1;\n  padding: 20px 0;\n}\n\n.MessgaeDate {\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n}\n\n.Label {\n  text-align: center;\n  padding: 2px 10px;\n  background: rgb(68, 68, 68);\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export var commentList = `Comment-module__CommentList-dAZyZ`;
export var messgaeDate = `Comment-module__MessgaeDate-Y7kP_`;
export var label = `Comment-module__Label-kn5cs`;
export default ___CSS_LOADER_EXPORT___;
