import { useCallback } from 'react'

import { ICreateReportClienArg } from '../../../api/endpoints.axios/report'
import { useReportService } from '../../../services/report.service'
export type FormState = ICreateReportClienArg

export function useCreateReport() {
  const reportService = useReportService()

  const createReport = useCallback(
    async (form: FormState) => {
      await reportService.getReport(form)
    },
    [reportService]
  )

  return {
    createReport,
    loading: reportService.isLoading,
    report: reportService.report,
  }
}
