import React from 'react'

// interface Props {
//     children: string;
// }

const ListTitle: React.FC<any> = (props): JSX.Element => {
  return (
    <div className="List__title">
      <h3>{props.children}</h3>
    </div>
  )
}

export default ListTitle
