import { createSlice } from '@reduxjs/toolkit'

import { User } from '../../models'
import { RootStore } from '..'

export interface InitialState {
  user?: User
  isLoggedIn: boolean
}

const initialState: InitialState = {
  isLoggedIn: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
    login: (state, { payload }) => {
      state.user = payload
      state.isLoggedIn = true
    },
  },
})

export default authSlice.reducer
export const { logout, login } = authSlice.actions
export const selectAuth = (state: RootStore) => state.auth
