import { axiosApi } from '..'

export interface ICreateCommentToRepair {
  defectId: number
  userId: number
  comment: string
}

export const reportApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    addCommentToRepair: build.mutation<void, ICreateCommentToRepair>({
      query: (data) => ({
        url: '/comment',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['RepairById'],
    }),
  }),
})

export const { useAddCommentToRepairMutation } = reportApi
