// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Drawer {
  position: fixed;
  height: 100vh;
  width: calc(100% - 20px);
  max-width: 400px;
  background: rgb(46.75, 46.75, 46.75);
  top: 0;
  right: 0;
  border-left: 1px solid rgb(58.5, 58.5, 58.5);
  transition: 400ms;
  z-index: 20;
}
.Drawer--show_false {
  transform: translateX(400px);
  transition: transform 400ms;
}
.Drawer--show_true {
  right: 0;
  transition: 400ms;
}
.Drawer__content {
  height: calc(100vh - 50px);
  overflow-y: scroll;
}
.Drawer__footer {
  padding: 10px;
  height: calc(100vh - 50px);
  overflow-y: scroll;
}
.Drawer + .Drawer {
  width: 350px;
}

@keyframes drawer {
  from {
    right: -400px;
  }
  to {
    right: 0;
  }
}
@keyframes drawerBack {
  from {
    right: 0px;
  }
  to {
    right: -400;
  }
}`, "",{"version":3,"sources":["webpack://./src/UI/Drawer/Drawer.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,aAAA;EACA,wBAAA;EACA,gBAAA;EACA,oCAAA;EACA,MAAA;EACA,QAAA;EACA,4CAAA;EACA,iBAAA;EACA,WAAA;AADF;AAGE;EAEE,4BAAA;EACA,2BAAA;AAFJ;AAKE;EACE,QAAA;EAEA,iBAAA;AAJJ;AAWE;EACE,0BAAA;EACA,kBAAA;AATJ;AAYE;EACE,aAAA;EACA,0BAAA;EACA,kBAAA;AAVJ;AAaE;EACE,YAAA;AAXJ;;AAeA;EACE;IACE,aAAA;EAZF;EAeA;IACE,QAAA;EAbF;AACF;AAgBA;EACE;IACE,UAAA;EAdF;EAiBA;IACE,WAAA;EAfF;AACF","sourcesContent":["@import '../../scss/const.scss';\n\n.Drawer {\n  position: fixed;\n  height: 100vh;\n  width: calc(100% - 20px);\n  max-width: 400px;\n  background: lighten(#222, 5%);\n  top: 0;\n  right: 0;\n  border-left: 1px solid $active-color;\n  transition: 400ms;\n  z-index: 20;\n\n  &--show_false {\n    // right: -400px;\n    transform: translateX(400px);\n    transition: transform 400ms;\n  }\n\n  &--show_true {\n    right: 0;\n    // width: 400px;\n    transition: 400ms;\n  }\n\n  &__header {\n    // padding: 10px;\n  }\n\n  &__content {\n    height: calc(100vh - 50px);\n    overflow-y: scroll;\n  }\n  \n  &__footer {\n    padding: 10px;\n    height: calc(100vh - 50px);\n    overflow-y: scroll;\n  }\n\n  &+.Drawer {\n    width: 350px;\n  }\n}\n\n@keyframes drawer {\n  from {\n    right: -400px\n  }\n\n  to {\n    right: 0\n  }\n}\n\n@keyframes drawerBack {\n  from {\n    right: 0px\n  }\n\n  to {\n    right: -400\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
