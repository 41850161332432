import React from 'react'
import { useController, UseControllerProps } from 'react-hook-form'

import { FormItem } from '../../UI'

interface ISelectProps extends UseControllerProps {
  placeholder: string
  label: string
  options: {
    id: number | string
    name: string
  }[]
}

export const SelectField: React.FC<ISelectProps> = (props) => {
  const { field, fieldState } = useController(props)
  return (
    <FormItem hint={fieldState.error?.message} label={props.label}>
      <select {...field} placeholder={props.placeholder} className="Input">
        {props.options?.map(({ id, name }) => (
          <option value={id} key={id}>
            {name}
          </option>
        ))}
      </select>
    </FormItem>
  )
}
