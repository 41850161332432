import arrayMutators from 'final-form-arrays'
import React, { useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useParams } from 'react-router-dom'

import { useGetChannelsByIdQuery } from '../../../api/endpoints.graphql/channel'
import { useGetPipelinesQuery } from '../../../api/endpoints.graphql/pipeline'
import { IRouterParams } from '../../../router'
import { Button, Section } from '../../../UI'
import { createGraphqlStringFromObject, gql } from '../../../utils/request'

export const ChannelsForm = () => {
  const { nodeId } = useParams<IRouterParams>()
  const [loading, setLoading] = useState<boolean>(false)
  const { data: channels } = useGetChannelsByIdQuery({ nodeId })
  const { data: pipelines } = useGetPipelinesQuery()

  const options = useMemo(
    () =>
      pipelines?.map((pipeline) => (
        <option value={pipeline.name} key={pipeline.id}>
          {pipeline.name}
        </option>
      )),
    [pipelines]
  )

  const handleClick = async (channels: any) => {
    setLoading(true)
    await gql(`mutation {
        updateChannels(channels: ${createGraphqlStringFromObject(
          channels.channels
        )}) { ID }
    }`)
    setLoading(false)
  }

  return (
    <Section>
      <Form
        onSubmit={handleClick}
        mutators={{ ...arrayMutators }}
        initialValues={{ channels }}
      >
        {({ handleSubmit, form, values }) => {
          return (
            <form
              className="Form"
              onSubmit={handleSubmit}
              onChange={(form) => {
                console.log(form)
              }}
            >
              <FieldArray name="channels">
                {({ fields }) => {
                  return (
                    <>
                      {fields.map((name, index) => {
                        return (
                          <div key={name}>
                            <Field
                              // key={`${name}.ID`}
                              component="input"
                              type="text"
                              className="Input"
                              name={`${name}.ID`}
                              disabled
                              style={{ width: '100px', marginRight: 10 }}
                            />
                            <Field
                              // key={`${name}.name`}
                              component="select"
                              type="text"
                              className="Input"
                              name={`${name}.pipeline.name`}
                              style={{ width: '100px' }}
                              value={'name'}
                            >
                              {options}
                            </Field>
                          </div>
                        )
                      })}
                    </>
                  )
                }}
              </FieldArray>

              <Button title={'Update'} loading={loading} />
            </form>
          )
        }}
      </Form>
    </Section>
  )
}
