import './Dialog.scss'

import React from 'react'

interface Props {
  message: string
}

const Dialog: React.FC<Props> = ({ message }) => {
  return <div className="Dialog">{message}</div>
}

export default Dialog
