import { format } from 'date-fns'
import { partialRight } from 'ramda'
import React, { useCallback } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'

import { store } from '../../store'
import { setPeriod } from '../../store/actions/indications'
import { Period, reportPeriodSelector } from '../../store/reducers/indications'
import { getEndDate, getStartDate } from '../indications/hooks'
const DATE_INPUT_FORMAT = 'yyyy-MM-dd'
const toDateInputFormat = partialRight(format, [DATE_INPUT_FORMAT])

const toPeriodDateInputFormat = (period: Period) => ({
  startDate: toDateInputFormat(getStartDate(period)),
  endDate: toDateInputFormat(getEndDate(period)),
})

const ReportDatePeriod: React.FC = () => {
  const dispatch = useDispatch()
  const period = useSelector(reportPeriodSelector)

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target
      dispatch(
        setPeriod({
          ...store.getState().indication.period,
          [name]: value,
        })
      )
    },
    [dispatch]
  )

  return (
    <Form<Period>
      onSubmit={(value) => console.log(value)}
      initialValues={toPeriodDateInputFormat(period)}
    >
      {({ handleSubmit }) => (
        <form
          className="Form Form--inline"
          onSubmit={handleSubmit}
          onChange={onChange}
        >
          <Field
            component="input"
            type="date"
            className="Input"
            name={'startDate'}
          />
          <Field
            component="input"
            type="date"
            className="Input"
            name={'endDate'}
          />
        </form>
      )}
    </Form>
  )
}

export default ReportDatePeriod
