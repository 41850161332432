import './List.scss'

import React, { useMemo, useState } from 'react'

import { Loader, Title } from '../../UI'
import ListContent from './ListContent'
import { IListItem } from './ListItem'

interface IListProps {
  title?: string
  content: JSX.Element[]
  filter?: keyof Pick<IListItem, 'subTitle' | 'text' | 'title'>
  actions?: any
  nav?: boolean
  loading?: boolean
  width?: number
  fixed?: boolean
  inline?: boolean
}

const List: React.FC<IListProps> = ({
  title,
  content,
  filter,
  actions,
  nav,
  loading,
  width,
  fixed,
  inline,
}) => {
  const STYLE = {
    maxWidth: width || 'inherit',
  }

  const classNames = useMemo(() => {
    let clss = 'List'
    clss += nav ? ' List__Nav' : ''
    clss += fixed ? ' List--Fixed' : ''
    return clss
  }, [nav, fixed])

  const [list, setList] = useState<string>('')

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setList(e.target.value)

  const filterByText = useMemo(() => {
    if (!filter) return content

    const regExp = new RegExp(list, 'i')
    return content.filter((listItem) => {
      return regExp.test(listItem.props[filter])
    })
  }, [filter, content, list])

  return (
    <div className={classNames} style={STYLE}>
      {(title || actions) && <Title title={title} actions={actions} />}

      {filter && (
        <div className="Title">
          <input
            type="text"
            className={'Input'}
            placeholder="Поиск"
            onChange={onChange}
            style={{ width: '100%' }}
          />
        </div>
      )}

      {loading && <Loader />}

      {!loading && <ListContent inline={inline}>{filterByText}</ListContent>}
    </div>
  )
}

export default List
