import cookie from 'cookie'
import {
  andThen,
  complement,
  compose,
  curry,
  equals,
  ifElse,
  includes,
  isEmpty,
  partial,
  partialRight,
  path,
  pipe,
  prop,
  tap,
} from 'ramda'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  IPostAutArgs,
  usePostAuthMutation,
} from '../../api/endpoints.axios/auth'
import { Role } from '../../const'
import { User } from '../../models'
import { Store } from '../../store'
import { login, logout } from '../../store/reducers/authSlice'

const getUserFormCookie = prop('user')

const parseJson = (user: any) => {
  if (user) {
    return JSON.parse(user)
  }
}

const getUserCookie = compose(parseJson, getUserFormCookie, cookie.parse)
const getUserRole = path<Role>(['role', 'role'])
const SECONDS = 60
const MINUTS = 60
const MS = 1000

const getMaxAge = (hourCount: number) => ({
  expires: new Date(+new Date() + SECONDS * hourCount * MINUTS * MS),
})

const setCookie = (cookie: string) => (document.cookie = cookie)
const setCookieWithParams = curry(compose(setCookie, cookie.serialize))
const setUserCookie = setCookieWithParams('user')
const removeUserCookie = () => setUserCookie('', getMaxAge(0))
const setUserCookieOnOneDay = partialRight(setUserCookie, [getMaxAge(24)])

export type IUseAuthService = () => {
  user: User
  logout: () => void
  isLoggedIn: boolean
  isCurrenUser: (currentUser: User) => boolean
  isRole: (role: Role) => boolean
  isAccess: (accessList: Role[]) => boolean
  auth: (form: IPostAutArgs) => void
}

export const useAuthService: IUseAuthService = () => {
  const dispatch = useDispatch()
  const { user, isLoggedIn } = useSelector((state: Store) => state.auth)
  const userCookie = getUserCookie(document.cookie)
  const history = useHistory()

  const isRole = partial<Role, Role, boolean>(equals, [
    getUserRole(user || userCookie) as Role,
  ])

  const isAccess = partialRight<Role[], unknown, boolean>(includes, [
    getUserRole(user || userCookie) as Role,
  ])

  const userLogout = useCallback(() => {
    removeUserCookie()
    dispatch(logout())
  }, [dispatch])

  useEffect(() => {
    if (!user && userCookie) {
      dispatch(login(userCookie))
    }
  }, [dispatch, user, userCookie])

  const isCurrenUser = useCallback(
    (currentUser): boolean => {
      return user?.id == currentUser?.id
    },
    [user?.id]
  )

  const [getUser, { isLoading }] = usePostAuthMutation()
  const dispatchLogin = compose(dispatch, login)

  const auth = compose(
    andThen(
      ifElse(
        complement(isEmpty),
        pipe(
          tap(console.log),
          tap(compose(setUserCookieOnOneDay, JSON.stringify)),
          tap(dispatchLogin),
          tap(() => history.push('/'))
        ),
        () => console.error('User not found')
      )
    ),
    andThen(prop('data')),
    getUser
  )

  return {
    user: user || userCookie,
    logout: userLogout,
    auth,
    isLoggedIn,
    isCurrenUser,
    isRole,
    isAccess,
  }
}
