import React from 'react'
import { Route } from 'react-router-dom'

import { Role } from '../const'
import { useAuthService } from '../services/auth.service'

interface Props {
  path: string
  access: Role[]
  view: React.FC
}

const AuthRoute: React.FC<Props> = ({ path, access, view }) => {
  const { isAccess, isLoggedIn } = useAuthService()

  if (!isLoggedIn || isAccess(access)) {
    return <h1>Unauthorized</h1>
  }

  return <Route path={path} component={view} />
}

export default AuthRoute
