import React, { useCallback } from 'react'
import { Form } from 'react-final-form'

import { HTTP_REQUEST_STATUS } from '../../api/constants'
import { useGetReportsQuery } from '../../api/endpoints.graphql/report'
import { useLayout } from '../../hooks/useLayout'
import { Report as ReportModel } from '../../models'
import { Layout, Loader, Section, Table } from '../../UI'
import { ReportForm } from './components'
import { INITIAL_REPORT_VALUES, reportTableConfig } from './constants'
import { useCreateReport } from './hooks'
import { useDownloadReport } from './hooks/useDownloadReport'

export const Report: React.FC = () => {
  const { data: reports } = useGetReportsQuery()
  const { downloadReport } = useDownloadReport()
  const { createReport, loading } = useCreateReport()
  const layout = useLayout()

  const action = useCallback(
    (report: ReportModel): JSX.Element => {
      let result: JSX.Element = (
        <a href="!#" onClick={(e) => downloadReport(e, report.id)}>
          Загрузить
        </a>
      )

      if (report.status === HTTP_REQUEST_STATUS.ERROR) {
        result = <p>{report.status}</p>
      }

      if (report.status === HTTP_REQUEST_STATUS.LOADING) {
        result = <Loader size="S" />
      }

      return result
    },
    [downloadReport]
  )

  return (
    <Layout className="Report" {...layout}>
      <Section>
        <h2>Параметры нового отчета</h2>
        <Form onSubmit={createReport} initialValues={INITIAL_REPORT_VALUES}>
          {({ handleSubmit }) => (
            <ReportForm handleSubmit={handleSubmit} loading={loading} />
          )}
        </Form>
      </Section>
      <Section>
        <h2>Отчеты</h2>
        <Table
          rowId={'ID'}
          header={reportTableConfig}
          rows={reports}
          action={action}
        />
      </Section>
    </Layout>
  )
}
