import JSZip from 'jszip'

export const useJszipService = (dirName: string) => {
  const zip = new JSZip()
  const dir = zip.folder(dirName)

  const addFileToZip = (name: string, data: Blob) => {
    if (!dir) {
      console.log('Zip folder is undefined')
      return
    }

    dir.file(name, data)
  }

  const getZipBlob = async () => {
    const zipBlob = await zip.generateAsync({ type: 'blob' })
    return zipBlob
  }

  return { addFileToZip, getZipBlob }
}
