import { IPipelineColumn, IPipelineName, IPipelineTitle } from './types'

export enum DateFormat {
  ddMMyy = 'dd.MM.yy',
  yyyyMMdd = 'yyyy.MM.dd',
}

export enum Role {
  Manager = 'manager',
  Serviceman = 'serviceman',
  admin = 'admin',
}

export enum HttpStatus {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export enum Service {
  SERVER = '/server',
  INDICATIONS = '/indications',
}

export const Pipeline: {
  id: number
  name: IPipelineName
  column: IPipelineColumn
  title: IPipelineTitle
}[] = [
  {
    id: 1,
    name: 'date',
    column: 'A',
    title: 'Дата',
  },
  {
    id: 2,
    name: 'Ppo1',
    column: 'B',
    title: 'Pпо1',
  },
  {
    id: 3,
    name: 'Tpo1',
    column: 'C',
    title: 'Tпо1',
  },
  {
    id: 4,
    name: 'Mpo1',
    column: 'D',
    title: 'Mпо1',
  },
  {
    id: 5,
    name: 'Qpo1',
    column: 'E',
    title: 'Qпо1',
  },
  {
    id: 6,
    name: 'Poo1',
    column: 'F',
    title: 'Pоо1',
  },
  {
    id: 7,
    name: 'Too1',
    column: 'G',
    title: 'Tоо1',
  },
  {
    id: 8,
    name: 'Moo1',
    column: 'H',
    title: 'Mоо1',
  },
  {
    id: 9,
    name: 'Qoo1',
    column: 'I',
    title: 'Qоо1',
  },
  {
    id: 10,
    name: 'Qotop1',
    column: 'J',
    title: 'Qотоп1',
  },
  {
    id: 11,
    name: 'Ppo2',
    column: 'K',
    title: 'Pпо2',
  },
  {
    id: 12,
    name: 'Tpo2',
    column: 'L',
    title: 'Tпо2',
  },
  {
    id: 13,
    name: 'Mpo2',
    column: 'M',
    title: 'Mпо2',
  },
  {
    id: 14,
    name: 'Qpo2',
    column: 'N',
    title: 'Qпо2',
  },
  {
    id: 15,
    name: 'Poo2',
    column: 'O',
    title: 'Pоо2',
  },
  {
    id: 16,
    name: 'Too2',
    column: 'P',
    title: 'Tоо2',
  },
  {
    id: 17,
    name: 'Moo2',
    column: 'Q',
    title: 'Mоо2',
  },
  {
    id: 18,
    name: 'Qoo2',
    column: 'R',
    title: 'Qоо2',
  },
  {
    id: 19,
    name: 'Qotop2',
    column: 'S',
    title: 'Qотоп2',
  },
  {
    id: 20,
    name: 'Ppo3',
    column: 'T',
    title: 'Pпо3',
  },
  {
    id: 21,
    name: 'Tpo3',
    column: 'U',
    title: 'Tпо3',
  },
  {
    id: 22,
    name: 'Mpo3',
    column: 'V',
    title: 'Mпо3',
  },
  {
    id: 23,
    name: 'Qpo3',
    column: 'W',
    title: 'Qпо3',
  },
  {
    id: 24,
    name: 'Ppo4',
    column: 'X',
    title: 'Pпо4',
  },
  {
    id: 25,
    name: 'Tpo4',
    column: 'Y',
    title: 'Tпо4',
  },
  {
    id: 26,
    name: 'Mpo4',
    column: 'Z',
    title: 'Mпо4',
  },
  {
    id: 27,
    name: 'Qpo4',
    column: 'AA',
    title: 'Qпо4',
  },
  {
    id: 28,
    name: '28',
    column: 'AB',
    title: '28',
  },
  {
    id: 29,
    name: 'Ppo',
    column: 'AC',
    title: 'Pпо',
  },
  {
    id: 30,
    name: 'Tpo',
    column: 'AD',
    title: 'Tпо',
  },
  {
    id: 31,
    name: 'Mpo',
    column: 'AE',
    title: 'Mпо',
  },
  {
    id: 32,
    name: 'Qpo',
    column: 'AF',
    title: 'Qпо',
  },
  {
    id: 33,
    name: 'Poo',
    column: 'AG',
    title: 'Pоо',
  },
  {
    id: 34,
    name: 'Too',
    column: 'AH',
    title: 'Tоо',
  },
  {
    id: 35,
    name: 'Moo',
    column: 'AI',
    title: 'Mоо',
  },
  {
    id: 36,
    name: 'Qoo',
    column: 'AJ',
    title: 'Qоо',
  },
  {
    id: 37,
    name: '37',
    column: 'AK',
    title: '37',
  },
  {
    id: 38,
    name: 'Ppb',
    column: 'AL',
    title: 'Pпб',
  },
  {
    id: 39,
    name: 'Tpb',
    column: 'AM',
    title: 'Tпб',
  },
  {
    id: 40,
    name: 'Mpb',
    column: 'AN',
    title: 'Mпб',
  },
  {
    id: 41,
    name: 'Qpb',
    column: 'AO',
    title: 'Qпб',
  },
  {
    id: 42,
    name: 'Pob',
    column: 'AP',
    title: 'Pоб',
  },
  {
    id: 43,
    name: 'Tob',
    column: 'AQ',
    title: 'Tоб',
  },
  {
    id: 44,
    name: 'Mob',
    column: 'AA',
    title: 'Mоб',
  },
  {
    id: 45,
    name: 'Qob',
    column: 'AS',
    title: 'Qоб',
  },
  {
    id: 46,
    name: 'Prev',
    column: 'AT',
    title: 'Pрев',
  },
  {
    id: 47,
    name: 'Trev',
    column: 'AU',
    title: 'Tрев',
  },
  {
    id: 48,
    name: 'Mrev',
    column: 'AV',
    title: 'Mрев',
  },
  {
    id: 49,
    name: 'Qrev',
    column: 'AW',
    title: 'Qрев',
  },
  {
    id: 50,
    name: 'Ppv',
    column: 'AX',
    title: 'Pпв',
  },
  {
    id: 51,
    name: 'Tpv',
    column: 'AY',
    title: 'Tпв',
  },
  {
    id: 52,
    name: 'Mpv',
    column: 'AZ',
    title: 'Mпв',
  },
  {
    id: 53,
    name: 'Qpv',
    column: 'BA',
    title: 'Qпв',
  },
  {
    id: 54,
    name: 'Pgvs',
    column: 'BB',
    title: 'Pгвс',
  },
  {
    id: 55,
    name: 'Tgvs',
    column: 'BC',
    title: 'Tгвс',
  },
  {
    id: 56,
    name: 'Mgvs',
    column: 'BD',
    title: 'Mгвс',
  },
  {
    id: 57,
    name: 'Qgvs',
    column: 'BE',
    title: 'Qгвс',
  },
  {
    id: 58,
    name: 'Pcirc',
    column: 'BF',
    title: 'Pцирк',
  },
  {
    id: 59,
    name: 'Tcirc',
    column: 'BG',
    title: 'Tцирк',
  },
  {
    id: 60,
    name: 'Mcirc',
    column: 'BH',
    title: 'Mцирк',
  },
  {
    id: 61,
    name: 'Qcirc',
    column: 'BI',
    title: 'Qцирк',
  },
  {
    id: 62,
    name: 'Pgvs2',
    column: 'BJ',
    title: 'Pгвс2',
  },
  {
    id: 63,
    name: 'Tgvs2',
    column: 'BK',
    title: 'Tгвс2',
  },
  {
    id: 64,
    name: 'Mgvs2',
    column: 'BL',
    title: 'Mгвс2',
  },
  {
    id: 65,
    name: 'Qgvs2',
    column: 'BM',
    title: 'Qгвс2',
  },
  {
    id: 66,
    name: 'Pcirc2',
    column: 'BN',
    title: 'Pцирк2',
  },
  {
    id: 67,
    name: 'Tcirc2',
    column: 'BO',
    title: 'Tцирк2',
  },
  {
    id: 68,
    name: 'Mcirc2',
    column: 'BP',
    title: 'Mцирк2',
  },
  {
    id: 69,
    name: 'Qcirc2',
    column: 'BQ',
    title: 'Qцирк2',
  },
  {
    id: 70,
    name: 'Pgvs3',
    column: 'BR',
    title: 'Pгвс3',
  },
  {
    id: 71,
    name: 'Tgvs3',
    column: 'BS',
    title: 'Tгвс3',
  },
  {
    id: 72,
    name: 'Mgvs3',
    column: 'BT',
    title: 'Mгвс3',
  },
  {
    id: 73,
    name: 'Qgvs3',
    column: 'BU',
    title: 'Qгвс3',
  },
  {
    id: 74,
    name: 'Pcirc3',
    column: 'BV',
    title: 'Pцирк3',
  },
  {
    id: 75,
    name: 'Tcirc3',
    column: 'BW',
    title: 'Tцирк3',
  },
  {
    id: 76,
    name: 'Mcirc3',
    column: 'BX',
    title: 'Mцирк3',
  },
  {
    id: 77,
    name: 'Qcirc3',
    column: 'BY',
    title: 'Qцирк3',
  },
  {
    id: 78,
    name: 'Qpotr',
    column: 'BZ',
    title: 'Qпотр',
  },
  {
    id: 79,
    name: 'Qotop',
    column: 'CA',
    title: 'Qотоп',
  },
  {
    id: 80,
    name: 'Qboil',
    column: 'CB',
    title: 'Qбойл',
  },
  {
    id: 81,
    name: 'Qgvs',
    column: 'CC',
    title: 'Qгвспотр',
  },
  {
    id: 82,
    name: 'Ggvs',
    column: 'CD',
    title: 'Gгвс',
  },
]
