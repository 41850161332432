import { Channel } from '../../models'
import { axiosApi } from '..'

export interface ICreateReportClienArg {
  startDate: string
  endDate: string
  provider: string
  consumer: string
  summer: boolean
}
export type ICreateReportClienRes = {
  name: string
  channels: Channel[]
}[]

export const reportApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    createReport: build.mutation<void, ICreateReportClienArg>({
      query: (data) => ({
        url: '/report',
        method: 'POST',
        data,
      }),
    }),
    createReportClien: build.mutation<
      ICreateReportClienRes,
      ICreateReportClienArg
    >({
      query: (data) => ({
        url: '/report/client',
        method: 'POST',
        data,
      }),
    }),
    downloadReport: build.query<void, { reportId: string }>({
      query: (params) => ({
        url: '/report/download',
        params,
      }),
    }),
  }),
})

export const {
  useCreateReportMutation,
  useDownloadReportQuery,
  useCreateReportClienMutation,
} = reportApi
