import { subDays } from 'date-fns'

import { IIndication } from '../../api/endpoints.axios/indication'
import { Pipeline } from '../../models'
import { RootStore } from '..'
import {
  SET_INDICATIONS,
  SET_PERIOD,
  SET_PIPELINES,
} from '../actionTypes/actionTypes'

export interface InitialState {
  indications: IIndication[]
  pipelines: Pipeline[]
  period: Period
}

export interface Period {
  startDate: string
  endDate: string
  summer: boolean
}

const initialState: InitialState = {
  indications: [],
  pipelines: [],
  period: {
    startDate: subDays(new Date(), 15).toDateString(),
    endDate: subDays(new Date(), 1).toDateString(),
    summer: false,
  },
}

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_INDICATIONS:
      return {
        ...state,
        indications: action.payload,
      }
    case SET_PIPELINES:
      return {
        ...state,
        pipelines: action.payload,
      }
    case SET_PERIOD:
      return {
        ...state,
        period: action.payload,
      }
    default:
      return state
  }
}

export const reportPeriodSelector = (state: RootStore) =>
  state.indication.period
