// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Info-module__info-PSpPa {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.Info-module__info-PSpPa .Info-module__item-VWNrK {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Info-module__title-RZvs3 {
  color: #747474;
  font-weight: 800;
}`, "",{"version":3,"sources":["webpack://./src/features/info/Info.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AACF;AAAE;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;;AAEA;EACE,cAAA;EACA,gBAAA;AACF","sourcesContent":[".info {\n  font-size: 12px;\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  .item {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n}\n\n.title {\n  color: #747474;\n  font-weight: 800;\n}\n"],"sourceRoot":""}]);
// Exports
export var info = `Info-module__info-PSpPa`;
export var item = `Info-module__item-VWNrK`;
export var title = `Info-module__title-RZvs3`;
export default ___CSS_LOADER_EXPORT___;
