import React from 'react'

import { AppRoute } from '../../router/routes'
import { List, ListItem } from '../../UI'

interface IMenuItem {
  title: string
  path: string
}

const footerMenu: IMenuItem[] = [
  { title: 'Ремонты', path: AppRoute.ServicemanRepairs },
  { title: 'На проверке', path: AppRoute.ServicemanTest },
  { title: 'Профиль', path: AppRoute.ServicemanProfile },
]

export const MobileFooter = () => {
  const renderMenuItem = (menuItem: IMenuItem) => (
    <ListItem
      key={menuItem.path}
      to={menuItem.path}
      text={menuItem.title.toUpperCase()}
    />
  )

  return <List nav inline content={footerMenu.map(renderMenuItem)} />
}
