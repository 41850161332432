import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useAuthService } from '../../services/auth.service'
import { Button, Form, FormItem } from '../../UI'

const loginSchema = yup.object({
  email: yup.string().required('Обязательно для заполненеия'),
  password: yup.string().required('Обязательно для заполненеия'),
})

export const LoginForm: React.FC = () => {
  const authService = useAuthService()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  })

  return (
    <Form onSubmit={handleSubmit(authService.auth)}>
      <FormItem hint={errors?.email?.message}>
        <input
          type="text"
          placeholder="Введите логин"
          className="Input"
          {...register('email')}
        />
        {/* <Field
          name="email"
          component="input"
          placeholder="Введите логин"
          className="Input"
          validate={(value: string) => value?.trim().length}
        /> */}
      </FormItem>
      <FormItem hint={errors?.password?.message}>
        <input
          type="password"
          placeholder="Введите пароль"
          className="Input"
          {...register('password')}
        />
        {/* <Field
          name="password"
          component="input"
          placeholder="Введите пароль"
          className="Input"
          type="password"
        /> */}
      </FormItem>
      <FormItem right>
        <Button title="Войти" />
      </FormItem>
    </Form>
  )
}
