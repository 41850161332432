import clsx from 'clsx'
import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'

import { Role } from '../../const'
import { useLayout } from '../../hooks/useLayout'
import { AppRoute } from '../../router'
import { useAuthService } from '../../services/auth.service'
import { Layout } from '../../UI'
import { PageNode } from '../Node'
import { ReportClient } from '../Report'
import * as reportStyles from '../Report/Report.module.scss'

export const Web: React.FC = () => {
  const location = useLocation()

  const { init } = useLayout(
    clsx({
      Node: /\/node/.test(location.pathname),
      [reportStyles.report]: /\/report/.test(location.pathname),
    })
  )

  const { isRole } = useAuthService()
  return (
    <Layout {...init}>
      <Route path={AppRoute.Node} component={PageNode} />
      <Route path={AppRoute.Report} exact component={ReportClient} />
      {isRole(Role.Manager) && <Redirect to={'/node'} />}
    </Layout>
  )
}
