import { GQL_SERVICE, HOST, PATH, PORT } from '../config'

export const createGraphqlStringFromObject = (values: any) => {
  return JSON.stringify(values, (key, value) => {
    if (typeof value === 'string') {
      return '*' + value + '*'
    }

    return value
  })
    .replace(/\\"/g, '')
    .replace(/\*/g, '"')
}

const hostname = `http://${HOST}`
const pathname = PATH || ''

export const gql = async (query: any) => {
  const url = new URL(pathname, hostname)
  url.port = PORT || ''
  url.pathname += GQL_SERVICE ?? ''
  url.pathname += '/graphql'

  try {
    let response: any = await fetch(url.toString(), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ query }),
    })

    if (response.ok) {
      response = await response.json()

      if (response.errors) {
        alert('HTTP ERROR\n' + response.errors[0].message)
      }

      return response.data
    } else {
      console.error('Server not started')
    }
  } catch (e) {
    error(e)
  }
}

export const rest = async (
  path: string,
  service: string,
  params: any
): Promise<any> => {
  const url = new URL(pathname, hostname)
  // url.pathname += service ?? '';
  url.pathname += path
  url.port = PORT || ''

  Object.keys(params).forEach((key: string) => {
    url.searchParams.set(key, params[key])
  })

  try {
    const res = await fetch(url.toString())
    return res
  } catch (e) {
    error(e)
  }
}

function error(e: any) {
  console.error('HTTP Error', e)
}
