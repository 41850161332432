import {} from '../../models'
import { axiosApi } from '..'

export type IIndication = {
  MeasureID: number
  Timemark: string
  ValOut: number
}
export type IGetIndicationsResponse = IIndication[]
export type IGetIndicationsArg = {
  ids: number[]
  startDate: string
  endDate: string
}
export type IDownloadExcelFileArg = {
  id: string
  startDate: string
  endDate: string
}

export const indicationsApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    getIndications: build.query<IGetIndicationsResponse, IGetIndicationsArg>({
      query: (queryArg) => ({
        url: '/sql',
        params: queryArg,
      }),
      // transformResponse: (res: { recordset: IIndication[] }[]) =>
      //   res[0].recordset,
    }),
    getExcel: build.query<void, IDownloadExcelFileArg>({
      query: (queryArg) => {
        return {
          responseType: 'arrayBuffer',
          url: '/excel',
          params: queryArg,
        }
      },
    }),
  }),
})

export const { useGetIndicationsQuery, useLazyGetExcelQuery } = indicationsApi
