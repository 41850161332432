import {
  SET_INDICATIONS,
  SET_PERIOD,
  SET_PIPELINES,
} from '../actionTypes/actionTypes'
import { Period } from '../reducers/indications'

export function setIndications(payload: any) {
  const action = {
    type: SET_INDICATIONS,
    payload,
  }

  return action
}

export function setPipelines(payload: any) {
  const action = {
    type: SET_PIPELINES,
    payload,
  }

  return action
}

export function setPeriod(payload: Period) {
  return {
    type: SET_PERIOD,
    payload,
  }
}
