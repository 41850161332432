import { compose, partialRight } from 'ramda'
import { useState } from 'react'

import { IGetIndicationsResponse } from '../../api/endpoints.axios/indication'
import {
  ICreateReportClienRes,
  useCreateReportClienMutation,
} from '../../api/endpoints.axios/report'
import {
  getCardNameWithExt,
  getNodeName,
  toFormatYyyyMmDd,
} from '../../features/indications/hooks'
import { FormState } from '../../pages/Report/hooks/useCreateReport'
import { splitOnChanks } from '../../utils'
import { useJszipService } from '../jszip.service/useJszipService'
import { getChannelIds, reduceIndicationsFormChanks } from './utils'

const AVAILABLE_TREADS = window.navigator.hardwareConcurrency

export type IIndictionServiceRespose = { recordset: IGetIndicationsResponse }

export const useReportService = () => {
  const [postCreateClient, { isLoading }] = useCreateReportClienMutation()
  const jsZipService = useJszipService('report')
  const getTimeDef = (start: number) => (+new Date() - start) / 1000
  const [progress, setProgress] = useState<boolean>(false)
  const [name, setName] = useState<string>()
  const [zip, setZip] = useState<Blob | null>()

  const [report, setReport] = useState<{
    zip?: Blob
    count?: number
    name?: string
    status?: string
    counter: number
  }>({
    zip: undefined,
    count: undefined,
    name: undefined,
    status: undefined,
    counter: 0,
  })

  const getReport = async (form: FormState) => {
    const start = +new Date()

    const logTimeDef = (str: string) => {
      console.log(str + ' on %s sec', getTimeDef(start))
    }

    const res = await postCreateClient(form)

    logTimeDef('Data downloaded')
    const values = res as {
      data: ICreateReportClienRes
    }

    const nodesWithChanels = values.data
    setReport((report) => ({ ...report, count: nodesWithChanels.length }))
    const chankSize = Math.ceil(nodesWithChanels.length / AVAILABLE_TREADS)
    const treadsCount = Math.ceil(nodesWithChanels.length / chankSize)
    const nodesWithChanelsChanks = splitOnChanks(nodesWithChanels, chankSize)
    const getDate = compose(toFormatYyyyMmDd, (date) => new Date(date))

    const getIndicationsByChannelIds = partialRight(
      reduceIndicationsFormChanks,
      [getDate(form.startDate), getDate(form.endDate)]
    )

    const getIndicatioins = compose(getIndicationsByChannelIds, getChannelIds)
    let complitedTreadsCounter = 0

    const done = async () => {
      complitedTreadsCounter++
      if (complitedTreadsCounter === treadsCount) {
        console.log('All thread complited!', complitedTreadsCounter)
        const zip = await jsZipService.getZipBlob()

        if (zip) {
          setReport((report) => ({
            ...report,
            name: undefined,
            counter: 0,
            zip,
            status: 'done',
          }))
        }

        logTimeDef('Report created')
      } else {
        console.log(
          'Thread %s of %s complited!',
          complitedTreadsCounter,
          treadsCount
        )
      }
    }

    setProgress(true)

    for (const nodesWithChanels of nodesWithChanelsChanks) {
      const instans = new Worker(new URL('./worker.ts', import.meta.url))
      try {
        instans.onmessage = async (data) => {
          if (data.data === 'done') {
            done()
          } else {
            setReport((report) => ({
              ...report,
              name: getNodeName(data),
              counter: report.counter + 1,
            }))
            jsZipService.addFileToZip(
              getCardNameWithExt(data),
              data.data.buffer
            )
          }
        }

        instans.postMessage({
          nodesWithChanels,
          indications: await getIndicatioins(nodesWithChanels),
          period: form,
        })
      } catch (e) {
        console.log('Error ')
      }
    }
  }

  return { getReport, isLoading: isLoading, report }
}
