import { Button } from 'antd'
import { assoc } from 'ramda'
import React, { useCallback, useMemo } from 'react'

import {
  useDeleteRepairByIdMutation,
  useUpdateRepairByIdMutation,
} from '../../../api/endpoints.axios/repairs'
import { Repair, RepairStatus } from '../../../models'
import { getActions } from '../blocks/ActiveRepair/utils'

export type IUseRepairActions = (repair: Repair) => {
  actions: JSX.Element[]
  loading: boolean
}

export const useRepairActions: IUseRepairActions = (repair) => {
  const [removeRepair, { isLoading: removeRepairLoading }] =
    useDeleteRepairByIdMutation()

  const [updateRepair, { isLoading: updateRepairLoading }] =
    useUpdateRepairByIdMutation()

  const accept = useCallback(() => {
    const setStatusDone = assoc('status', RepairStatus.Done)
    updateRepair({
      repairId: String(repair.id),
      updatedRepair: setStatusDone(repair),
    })
  }, [repair, updateRepair])

  const remove = useCallback(() => {
    removeRepair({ repairId: String(repair.id) })
  }, [removeRepair, repair])

  const actions = useMemo(
    () => [
      <Button key={'accept'} title="Принять" onClick={accept} type="primary">
        Принять
      </Button>,
      <Button key={'remove'} title="Удалить" onClick={remove} type="primary">
        Удалить
      </Button>,
      <Button
        key={'accepted'}
        title="Принято"
        icon="✅"
        disabled
        type="primary"
      >
        Принято
      </Button>,
    ],
    [accept, remove]
  )

  return {
    actions: getActions(repair)(actions),
    loading: removeRepairLoading || updateRepairLoading,
  }
}
