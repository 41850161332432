import React from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { usePostRepairsMutation } from '../../../../api/endpoints.axios/repairs'
import { TextField } from '../../../../components/useFormFields'
import { RepairStatus } from '../../../../models'
import { IRouterParams } from '../../../../router'
import { useAuthService } from '../../../../services/auth.service'
import { Form } from '../../../../UI'
import * as styles from './NewRepairForm.module.scss'

export function NewRepairForm() {
  const [createRepair, { isLoading }] = usePostRepairsMutation()
  const { nodeId } = useParams<IRouterParams>()
  const { user } = useAuthService()

  const onSubmit = async ({ defect }: { defect: string }) => {
    try {
      console.log(defect)
      await createRepair({
        defect,
        nodeId: Number(nodeId),
        status: RepairStatus.New,
        createdBy: user.id,
      }).unwrap()
    } catch (e) {
      alert(e.data.errorMessage)
    }
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm()

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <TextField placeholder="Новый ремонт" name="defect" control={control} />
      <input className="Button" type="submit" />
    </Form>
  )
}
