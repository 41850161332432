import { QueryBuilder } from '@serafim_v/gql-query-builder'

import { Channel, Comment, Node, Repair, Scheme } from '../../models'
import { grphqlApi } from '..'

export type IGetNodesResponse = { nodes: Node[] }
export type IGetNodesTransformResponse = Node[]

const repairsEntity = new QueryBuilder<Repair>('repairs')
const comentsEntity = new QueryBuilder<Comment>('comments')
const schemaEntity = new QueryBuilder<Scheme>('scheme')
const channelEntity = new QueryBuilder<Channel>('channels')

const nodeEntity = new QueryBuilder<Node>('nodes')
  .addProperty('id')
  .addProperty('name')
  .addProperty('geu')
  .addEntity(
    repairsEntity
      .addProperty('status')
      .addEntity(comentsEntity.addProperty('comment'))
  )
  .execute()

export const nodeApi = grphqlApi.injectEndpoints({
  endpoints: (build) => ({
    getNodes: build.query<IGetNodesTransformResponse, void>({
      providesTags: ['Node'],
      query: () => ({ document: nodeEntity }),
      transformResponse: (respose: IGetNodesResponse) => respose.nodes,
    }),
    getNode: build.query<Node, { nodeId: string }>({
      query: ({ nodeId }) => ({
        document: new QueryBuilder<Node>('node')
          .addProperty('name')
          .addProperty('geu')
          .addEntity(
            schemaEntity
              .addProperty('scheme')
              .addProperty('schemeActual')
              .addProperty('formulaSum')
              .addProperty('formulaWin')
              .addProperty('chartHeating')
              .addProperty('chartHws')
              .addProperty('heatLoad1')
              .addProperty('heatLoad2')
              .addProperty('ths')
          )
          .addEntity(
            channelEntity
              .addProperty('ID')
              .addProperty('name')
              .addProperty('pipeline')
          )
          .execute({ nodeId }),
      }),
      transformResponse: (respose: { node: Node }) => respose.node || [],
    }),
  }),
})

export const { useGetNodesQuery, useGetNodeQuery } = nodeApi
