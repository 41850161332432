import { compose, partial, prop } from 'ramda'
import React, { useMemo } from 'react'
import { Route } from 'react-router-dom'

import Indications from '../../features/indications'
import Info from '../../features/info/Info'
import { RepairSide } from '../../features/repairSide'
import { useLayout } from '../../hooks/useLayout'
import { useNodeEditModal } from '../../hooks/useNodeEditModal'
import { useRepairsDrawer } from '../../hooks/useRepairsDrawer'
import { AppRoute } from '../../router'
import { useNodeService } from '../../services/Node.service'
import { Layout, Title } from '../../UI'
import * as styles from './Node.module.scss'

const getName = prop('name')
const addIconBefore = (icon: string, text: string) => `${icon} ${text}`
const addHomeIcon = partial(addIconBefore, ['🏠'])
const getTitleWithHomeIcon = compose(addHomeIcon, getName)

export const NodeById: React.FC = () => {
  const { node, isFetching } = useNodeService()
  const repaireDrawer = useRepairsDrawer()
  const nodeEditModal = useNodeEditModal(node)
  const title = useMemo(() => getTitleWithHomeIcon(node), [node])

  const nodeActions = (
    <>
      {nodeEditModal}
      {repaireDrawer}
    </>
  )

  return (
    <>
      <Title title={title} actions={nodeActions} />
      <div className={styles.contentWrapper}>
        <div className={styles.rightPanel}>
          <RepairSide />
        </div>
        <Info node={node} loading={isFetching} />
        <Indications />
        {/* <DeviceList /> */}
      </div>
    </>
  )
}

export const PageNode: React.FC = () => {
  const layout = useLayout()

  return (
    <Layout empty {...layout.node}>
      <Route exec path={AppRoute.NodeById} component={NodeById} />
    </Layout>
  )
}
