export interface IRoute {
  path: string
  name: string
  view?: any
  exact?: boolean
  guard: string[]
  icon?: any
}

export interface IRouterParams {
  nodeId: string
  repairId: string
}
