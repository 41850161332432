import { Pipeline } from '../../const'
import { IPipelineColumn, IPipelineName } from '../../const/types'

export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]
export const DISBALANS_COLUMNS_NUMBERS = [85, 86, 87, 88]
export const DATE_FORMAT = 'dd.MM.yyyy'
export const EXCEL_EXT = '.xlsx'
export const TOTAL_COLUMNS = [78, 79, 80, 81, 82]

export enum DisbalansValue {
  Close = 2.8,
  Open = 3.4,
  Circulation = 4,
}

type IColumnNameMap = Record<IPipelineName, IPipelineColumn>

export const XlCol = Pipeline.reduce((acc, curr) => {
  acc[curr.name] = curr.column
  return acc
}, {} as IColumnNameMap)
