import React from 'react'

import { Section } from '../../UI'
import { NewRepairForm } from './blocks/NewRepairForm/NewRepairForm'
import OldRepairList from './blocks/OldRepairList/OldRepairList'

export function RepairSide() {
  return (
    <Section>
      <NewRepairForm />
      <OldRepairList />
    </Section>
  )
}
