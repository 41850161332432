import './Content.scss'

import clsx from 'clsx'
import React from 'react'

export interface IContentProps {
  className?: string
}

export const Content: React.FC<IContentProps> = ({ children, className }) => {
  return <main className={clsx('Content', className)}>{children}</main>
}
