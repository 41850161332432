import { QueryBuilder } from '@serafim_v/gql-query-builder'

import { Consumer } from '../../models'
import { grphqlApi } from '..'

export type IGetConsumersResponse = { consumers: Consumer[] }
export type IGetConsumersArgs = void

export const consumerEntity = new QueryBuilder<Consumer>('consumers')
  .addProperty('id')
  .addProperty('name')

// export const query = new QueryBuilder<Consumer>(consumerEntity).create()

export const consumerApi = grphqlApi.injectEndpoints({
  endpoints: (build) => ({
    getConsumers: build.query<Consumer[], IGetConsumersArgs>({
      query: () => ({
        document: consumerEntity.execute(),
      }),
      transformResponse: (response: IGetConsumersResponse) =>
        response.consumers,
    }),
  }),
})

export const { useGetConsumersQuery } = consumerApi
