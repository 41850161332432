import { axiosApi } from '../..'
import { IRepairDto } from '../types'

export type IPostRepairsDTO = Pick<
  IRepairDto,
  'nodeId' | 'status' | 'createdBy' | 'defect'
>

export const repairsApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    getRepairs: build.query<IRepairDto[], void>({
      query: () => ({
        url: '/repair',
      }),
      transformResponse: (respose: IRepairDto[]) => respose || [],
    }),
    getRepairsByStatusAndUser: build.query<IRepairDto[], number>({
      query: (statusId) => ({
        url: `/repair/status/${statusId}`,
      }),
      transformResponse: (respose: IRepairDto[]) => respose || [],
    }),
    getRepairsByNodeId: build.query<IRepairDto[], { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `/node/${nodeId}/repair`,
      }),
      providesTags: ['RepairsById', 'Repairs'],
    }),
    getRepairById: build.query<IRepairDto, { repairId: string }>({
      query: ({ repairId }) => ({
        url: `/repair/${repairId}`,
      }),
      providesTags: ['Repairs', 'RepairById'],
    }),
    deleteRepairById: build.mutation<void, { repairId: string }>({
      query: (queryArg) => ({
        url: `/repair/${queryArg['repairId']}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Repairs'],
    }),
    updateRepairById: build.mutation<
      void,
      { repairId: string; updatedRepair: IRepairDto }
    >({
      query: ({ repairId, updatedRepair }) => {
        return {
          url: `/repair/${repairId}`,
          data: updatedRepair,
          method: 'PATCH',
        }
      },
      invalidatesTags: ['Repairs'],
    }),
    postRepairs: build.mutation<number, IPostRepairsDTO>({
      query: (data) => ({
        url: `/repair`,
        data,
        method: 'POST',
      }),
      invalidatesTags: ['RepairsById'],
    }),
  }),
})

export const {
  useGetRepairsQuery,
  useGetRepairsByNodeIdQuery,
  usePostRepairsMutation,
  useGetRepairByIdQuery,
  useDeleteRepairByIdMutation,
  useUpdateRepairByIdMutation,
  useGetRepairsByStatusAndUserQuery,
} = repairsApi
