import './Avatar.scss'

import React from 'react'

import { User } from '../../models'

interface Props {
  user: User
}

const Avatar: React.FC<any> = ({ user }) => {
  return (
    <div className="Avatar">
      <div className="Photo">👱‍♂️</div>
      <p>{user.profile?.name}</p>
    </div>
  )
}

export default Avatar
