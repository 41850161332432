export function getCountDaysBack(date: number) {
  return Math.floor((+new Date() - +new Date(date)) / 1000 / 60 / 60 / 24)
}

export const moment = (stamp: string) => {
  const moment = Number(new Date(stamp))
  const now = Number(new Date())
  const daysBack = Math.floor((now - moment) / 1000 / 60 / 60 / 24)
  return {
    back(): string {
      if (daysBack === 0) {
        return 'сегодня'
      } else if (daysBack === 1) {
        return 'вчера'
      } else if (daysBack === 2) {
        return 'позавчера'
      }

      return new Date(stamp).toLocaleDateString()
    },
  }
}

export const splitOnChanks = <T>(arr: T[], chankSize: number): T[][] => {
  let chank: T[] = []
  return arr.reduce((chanks: T[][], item, index) => {
    chank.push(item)

    if (arr.length - 1 === index) {
      chanks.push(chank)
    } else if (chank.length === chankSize) {
      chanks.push(chank)
      chank = []
    }

    return chanks
  }, [])
}
