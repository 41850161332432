import React from 'react'
import { NavLink } from 'react-router-dom'

export interface IListItem {
  title?: string
  subTitle?: string
  text?: string
  to?: string
  event?: () => void
  actions?: JSX.Element
}

const ListItem: React.FC<IListItem> = ({
  text,
  to,
  event,
  title,
  subTitle,
  actions,
}) => {
  if (to) {
    return (
      <NavLink to={to} className="List__link">
        <li className="List__item">
          <div className="ListItem__content">
            <p className="ListItem__title">{title}</p>
            <p className="ListItem__subtitle">{subTitle}</p>
            <p className="ListItem__text">{text}</p>
          </div>
          {actions && <div className="ListItem__actions">{actions}</div>}
        </li>
      </NavLink>
    )
  }

  return (
    <li className="List__item ListItem" onClick={event}>
      <div className="ListItem__content">
        <p className="ListItem__title">{title}</p>
        <p className="ListItem__subtitle">{subTitle}</p>
        <p className="ListItem__text">{text && text}</p>
      </div>
      {actions && <div className="ListItem__actions">{actions}</div>}
    </li>
  )
}

export default ListItem
