// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header-module__Header-tIcNI {
  height: 50px;
  padding: 10px;
  border-bottom: 1px solid rgb(71.25, 71.25, 71.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Header-module__Header__actions-I02VW {
  display: flex;
  justify-content: end;
  width: 450px;
}

.Mobile .Header-module__Header-tIcNI {
  justify-content: start;
}

.Header-module__logo-AQj0q {
  margin-right: 5px;
  width: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,iDAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,aAAA;EACA,oBAAA;EACA,YAAA;AADJ;;AAME;EACE,sBAAA;AAHJ;;AAOA;EACE,iBAAA;EACA,WAAA;AAJF","sourcesContent":["@import '../../scss/const.scss';\n\n.Header {\n  height: 50px;\n  padding: 10px;\n  border-bottom: 1px solid $secondary-color;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  &__actions {\n    display: flex;\n    justify-content: end;\n    width: 450px;\n  }\n}\n\n:global(.Mobile) {\n  .Header {\n    justify-content: start;\n  }\n}\n\n.logo {\n  margin-right: 5px;\n  width: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export var header = `Header-module__Header-tIcNI`;
export var headerActions = `Header-module__Header__actions-I02VW`;
export var logo = `Header-module__logo-AQj0q`;
export default ___CSS_LOADER_EXPORT___;
