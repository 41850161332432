import { axiosApi } from '..'

export interface IPostAutArgs {
  email: string
  password: string
}

export const authApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    postAuth: build.mutation<void, IPostAutArgs>({
      query: (data) => ({
        url: '/auth',
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const { usePostAuthMutation } = authApi
