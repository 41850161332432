import { andThen, compose } from 'ramda'

export const useFileSaverService = () => {
  const createAndDownloadAndRemove = (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  const saveBlob = (blob: Blob, fileName: string) => {
    createAndDownloadAndRemove(blob, fileName)
  }

  const saveArrayBuffer = (buffer: ArrayBuffer, fileName: string) => {
    const blob = new Blob([buffer])
    createAndDownloadAndRemove(blob, fileName)
  }

  return {
    saveBlob,
    saveArrayBuffer,
  }
}

export const useFileReader = () => {
  const readFile = async (fileSrc: string) => await fetch(fileSrc)
  const getBuffer = async (file: any) => await file.arrayBuffer()
  const readFileToBuffer = compose(andThen(getBuffer), readFile)
  return { readFileToBuffer }
}
