import React from 'react'
import { useForm } from 'react-hook-form'

import {
  ICreateCommentToRepair,
  useAddCommentToRepairMutation,
} from '../../../../api/endpoints.axios/comments'
import { TextField } from '../../../../components/useFormFields'
import { useAuthService } from '../../../../services/auth.service'
import { Form } from '../../../../UI'
import * as styles from './NewCommentForm.module.scss'

export function NewCommentForm({ repair }) {
  const [postMessage, { isLoading }] = useAddCommentToRepairMutation()
  const { user } = useAuthService()

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Pick<ICreateCommentToRepair, 'userId' | 'defectId'>>({
    defaultValues: {
      defectId: repair?.id,
      userId: Number(user?.id),
    },
  })

  const onSubmit = (
    value: Pick<ICreateCommentToRepair, 'userId' | 'defectId'>
  ) => {
    postMessage(value)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <TextField
        placeholder="Новый комментарий"
        name="comment"
        control={control}
      />
      <input className="Button" type="submit" />
    </Form>
  )
}
