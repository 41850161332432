import { AppRoute } from './routes'

export const menu: {
  path: AppRoute
  name: string
}[] = [
  {
    path: AppRoute.Node,
    name: 'Мониторинг',
  },
  {
    path: AppRoute.Report,
    name: 'Отчеты',
  },
]
