import './Layout.scss'

import clsx from 'clsx'
import React from 'react'

import * as LayoutComponents from '../App'

interface Props {
  header?: JSX.Element
  footer?: JSX.Element
  aside?: JSX.Element
  content?: JSX.Element
  className?: string
  empty?: boolean
}

export const Layout: React.FC<Props> = ({
  header,
  aside,
  footer,
  children,
  content,
  className,
  empty,
}) => {
  const layout = (
    <>
      {' '}
      {header && (
        <LayoutComponents.AppHeader>{header}</LayoutComponents.AppHeader>
      )}
      {aside && <LayoutComponents.Aside>{aside}</LayoutComponents.Aside>}
      {content && (
        <LayoutComponents.Content>{content}</LayoutComponents.Content>
      )}
      {empty ? (
        <LayoutComponents.Content>{children}</LayoutComponents.Content>
      ) : (
        children
      )}
      {footer && (
        <LayoutComponents.AppFooter>{footer}</LayoutComponents.AppFooter>
      )}
    </>
  )

  return empty ? (
    layout
  ) : (
    <div className={clsx(['Layout', className])}>{layout}</div>
  )
}
