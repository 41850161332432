import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import client from '../../../api/axiosInstans'
import { useFileSaverService } from '../../../services/FileSaver.service'
import { selectNodesCurrentNode } from '../../../store/reducers/nodesSlice'

interface Params {
  nodeId: string
}
interface Form {
  startDate: string
  endDate: string
}

export const useDownloadCardFromServer = () => {
  const [loading, setLoading] = React.useState(false)
  const node = useSelector(selectNodesCurrentNode)
  const { nodeId } = useParams<Params>()
  const save = useFileSaverService()

  const downloadCard = useCallback(
    async (form: Form) => {
      try {
        setLoading(true)
        const res = await client('/excel', {
          params: {
            id: nodeId,
            ...form,
          },
          responseType: 'blob',
        })

        const blob = new Blob([res.data])
        save(
          blob,
          node
            ? `${node.name} ${new Date().toLocaleDateString()}.xlsx`
            : 'testFile.xlsx'
        )
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [node, nodeId, save]
  )

  return { downloadCard, loading }
}
