import { QueryBuilder } from '@serafim_v/gql-query-builder'

import { Report } from '../../models'
import { grphqlApi } from '..'

export type IGetReportsResponse = { reports: Report[] }
export type IGetReportsArgs = void
//id name status provider {name}  {name} endDate startDate createdAt
export const reportEntity = new QueryBuilder<Report>('reports')
  .addProperty('id')
  .addProperty('name')
  .addProperty('endDate')
  .addProperty('startDate')
  .addEntity(new QueryBuilder<Report>('provider').addProperty('name'))
  .addEntity(new QueryBuilder<Report>('consumer').addProperty('name'))

// export const query = new QueryBuilder<Report>(reportEntity).create()

export const reportApi = grphqlApi.injectEndpoints({
  endpoints: (build) => ({
    getReports: build.query<Report[], IGetReportsArgs>({
      query: () => ({
        document: reportEntity.execute(),
      }),
      transformResponse: (response: IGetReportsResponse) => response.reports,
    }),
  }),
})

export const { useGetReportsQuery } = reportApi
