import { compose, propEq } from 'ramda'
import React from 'react'
import { Form } from 'react-final-form'
import { useHistory, useParams } from 'react-router'

import { useAddCommentToRepairMutation } from '../../../../api/endpoints.axios/comments'
import { useGetRepairByIdQuery } from '../../../../api/endpoints.axios/repairs'
import CommentsList from '../../../../features/repairSide/blocks/CommentList/CommentsList'
import { RepairItem } from '../../../../features/repairSide/blocks/RepairItem'
import { Repair } from '../../../../models'
import { useAuthService } from '../../../../services/auth.service'
import { Modal } from '../../../../UI'
import { RepairForm } from './Form'
import { getLocation } from './RepairListForServiceMan'

export const ModalForm = ({ getRepairById }: { getRepairById: any }) => {
  const { repairId } = useParams<{ repairId: string }>()
  const history = useHistory()
  const getRepair = compose(getRepairById, propEq('id'), Number)
  const [sentComment, { isLoading }] = useAddCommentToRepairMutation()

  const { data: repair, isFetching } = useGetRepairByIdQuery({
    repairId,
  })

  const onSubmit = async (values: any) => {
    try {
      await sentComment(values).unwrap()
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  const { user } = useAuthService()

  // const repair = useMemo(
  //   () => getRepair(repairId) as Repair,
  //   [getRepair, repairId]
  // )

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        userId: Number(user?.id),
        defectId: Number(repair?.id),
      }}
    >
      {({ handleSubmit }) => {
        return (
          <Modal
            closeModal={history.goBack}
            title={(getRepair(repairId) as Repair)?.node?.name}
            type={'sheet'}
            onOk={handleSubmit}
            isLoading={isFetching}
          >
            {getLocation(getRepair(repairId))}
            <RepairItem repair={repair!}>
              <CommentsList repair={repair!} />
            </RepairItem>
            <RepairForm activeRepair={repair} />
          </Modal>
        )
      }}
    </Form>
  )
}
