import './Title.scss'

import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

export interface ITitleProps {
  children?: JSX.Element | JSX.Element[]
  title?: string | JSX.Element
  actions?: JSX.Element | JSX.Element[] | string
  className?: string
  color?: string
  border?: 'top' | 'none' | 'top bottom'
}

const Title: FunctionComponent<ITitleProps> = ({
  title,
  actions,
  className,
  color,
  children,
  border,
}) => {
  const STYLE = {
    background: color ? color : 'none',
  }

  return (
    <div
      className={clsx([
        'Title',
        className,
        {
          'Title--bb': !border || /bottom/.test(String(border)),
          'Title--bt': /top/.test(String(border)),
        },
      ])}
      style={STYLE}
    >
      {title && <h3>{title}</h3>}
      {children && children}
      {actions && <div className="Title__actions">{actions}</div>}
    </div>
  )
}

export default Title
