import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import { AxiosError, AxiosRequestConfig } from 'axios'

import axiosInstans from './axiosInstans'

export const client =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method?: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async (options) => {
    try {
      const result = await axiosInstans(options)
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }
