import { createSlice } from '@reduxjs/toolkit'

import { Node } from '../../models'
import { RootStore } from '..'

export interface InitialState {
  nodes: Node[]
  node?: Node
}

const initialState: InitialState = {
  nodes: [],
}

export const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    setNode: (state, { payload }) => {
      state.node = payload
    },
    setNodes: (state, { payload }) => {
      state.nodes = payload
    },
  },
})

export const selectNodesAllNodes = (state: RootStore) => state.nodes.nodes
export const selectNodesCurrentNode = (state: RootStore) => state.nodes.node
