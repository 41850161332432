import { QueryBuilder } from '@serafim_v/gql-query-builder'

import { Pipeline } from '../../models'
import { grphqlApi } from '..'

export const query = new QueryBuilder<Pipeline>('pipeline')
  .addProperty('id')
  .addProperty('name')

export type IGetPipelinesRespose = { pipeline: Pipeline[] }
export type IGetPipelinesTransformedRespose = Pipeline[]

export const pipelineApi = grphqlApi.injectEndpoints({
  endpoints: (build) => ({
    getPipelines: build.query<IGetPipelinesTransformedRespose, void>({
      query: () => ({
        document: query.execute(),
      }),
      transformResponse: (response: IGetPipelinesRespose) => response.pipeline,
    }),
  }),
})

export const { useGetPipelinesQuery } = pipelineApi
