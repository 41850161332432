// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switch {
  border: none;
  display: flex;
  align-items: center;
  position: relative;
}
.Switch input[type=checkbox] + span {
  display: inline-block;
  width: 30px;
  height: 20px;
  background-color: red;
  position: relative;
  border-radius: 10px;
}
.Switch input[type=checkbox] + span::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 16px;
  width: 16px;
  background: bisque;
  transition: 0.2s;
  border-radius: 9px;
}
.Switch input[type=checkbox]:checked + span {
  background-color: green;
}
.Switch input[type=checkbox]:checked + span::after {
  left: 12px;
}`, "",{"version":3,"sources":["webpack://./src/UI/Switch/Switch.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACD;AAEE;EACC,qBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;AAAH;AAEG;EACC,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAIG;EACC,uBAAA;AAFJ;AAGI;EAEC,UAAA;AAFL","sourcesContent":[".Switch {\n\tborder: none;\n\tdisplay: flex;\n\talign-items: center;\n\tposition: relative;\n\n\tinput[type=checkbox] {\n\t\t+span {\n\t\t\tdisplay: inline-block;\n\t\t\twidth: 30px;\n\t\t\theight: 20px;\n\t\t\tbackground-color: red;\n\t\t\tposition: relative;\n\t\t\tborder-radius: 10px;\n\n\t\t\t&::after {\n\t\t\t\tcontent: '';\n\t\t\t\tdisplay: inline-block;\n\t\t\t\tposition: absolute;\n\t\t\t\ttop: 2px;\n\t\t\t\tleft: 2px;\n\t\t\t\theight: 16px;\n\t\t\t\twidth: 16px;\n\t\t\t\tbackground: bisque;\n\t\t\t\ttransition: 0.2s;\n\t\t\t\tborder-radius: 9px;\n\t\t\t}\n\t\t}\n\t\t&:checked {\n\t\t\t+span {\n\t\t\t\tbackground-color: green;\n\t\t\t\t&::after {\n\t\t\t\t\n\t\t\t\t\tleft: 12px;\n\t\t\t\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
