import clsx from 'clsx'
import React from 'react'

export interface IFormProps {
  onSubmit?: () => void
  inline?: boolean
  className?: string
}

export const Form: React.FC<IFormProps> = ({
  className,
  children,
  onSubmit,
  inline,
}) => {
  return (
    <form
      onSubmit={onSubmit}
      className={clsx([
        className,
        'Form',
        {
          'Form--inline': inline,
        },
      ])}
    >
      {children}
    </form>
  )
}

export interface IFormItemProps {
  label?: string
  hint?: string
  right?: boolean
}

export const FormItem: React.FC<IFormItemProps> = ({
  children,
  label,
  hint,
  right,
}) => {
  return (
    <div
      className={clsx([
        'FormItem',
        {
          'FormItem--right': right,
        },
      ])}
    >
      {label && (
        <label htmlFor="" className={clsx(['FormItem__label'])}>
          {label}
        </label>
      )}
      <div className={clsx(['FormItem__input'])}>
        {children}
        {hint && <p className="FormItem__hint">{hint}</p>}
      </div>
    </div>
  )
}
