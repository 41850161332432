import './Switch.scss'

import React from 'react'

const Switch: React.FC = () => (
  <label className="Input Switch">
    <input type="checkbox" hidden />
    <span></span>
    Летняя карточка
  </label>
)

export default Switch
