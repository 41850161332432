import React, { PropsWithChildren } from 'react'

import { IRepairDto } from '../../../../api/endpoints.axios/types'
import { Card } from '../../../../UI'
import { ICardProps } from '../../../../UI/Card/Card'
import { useRepairActions } from '../../hooks/useRepairActions'
import { getDefect, getTitle } from '../ActiveRepair/utils'
import * as styles from './RepairItem.module.scss'

export const RepairItem: React.FC<
  PropsWithChildren<
    { repair: IRepairDto } & Pick<ICardProps, 'title' | 'actions'>
  >
> = ({ children, repair, title }) => {
  const { actions, loading: actionLoading } = useRepairActions(repair!)
  return (
    <Card
      key={repair?.id}
      title={title}
      actions={actions}
      content={
        <div className={styles.commentWrapper}>
          <b>{getTitle(repair)}</b>
          <p>{getDefect(repair)}</p>
          {children}
        </div>
      }
      column
    />
  )
}
