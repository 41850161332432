import { QueryBuilder } from '@serafim_v/gql-query-builder'

import { Channel, Pipeline } from '../../models'
import { grphqlApi } from '..'

export type IGetChannelsByIdResponse = { channels: Channel[] }
export type IGetChannelsByIdArgs = { nodeId: string }

export const channelEntity = new QueryBuilder<Channel>('channels')
  .addProperty('ID')
  .addEntity(
    new QueryBuilder<Pipeline>('pipeline').addProperty('name').addProperty('id')
  )

export const channelApi = grphqlApi.injectEndpoints({
  endpoints: (build) => ({
    getChannelsById: build.query<Channel[], IGetChannelsByIdArgs>({
      query: ({ nodeId }) => ({
        document: channelEntity.execute({ id: nodeId }),
      }),
      transformResponse: (response: IGetChannelsByIdResponse) =>
        response?.channels || [],
    }),
  }),
})

export const { useGetChannelsByIdQuery } = channelApi
