import React from 'react'
import { Field, useForm } from 'react-final-form'

import { Repair } from '../../../../models'

export function RepairForm({ activeRepair }: { activeRepair: Repair }) {
  const form = useForm()
  return (
    <form onSubmit={form.submit}>
      <Field
        name="comment"
        component="textarea"
        placeholder="Заключение"
        className="Textarea"
      />
    </form>
  )
}
