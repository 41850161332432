import React from 'react'

import { useLayout } from '../../hooks/useLayout'
import { useAuthService } from '../../services/auth.service'
import { Button } from '../../UI'

export const Profile: React.FC = () => {
  const { init } = useLayout()
  const authService = useAuthService()
  return <Button onClick={authService.logout} title="Выйти" />
}
