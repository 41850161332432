// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.Comment {
  width: 80%;
  max-width: 425px;
  background: #555;
  padding: 5px 10px;
  border-radius: 5px;
  border-bottom: 3px solid #333;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}
.Comment__Actions {
  min-width: 125px;
}
.Comment__Time {
  font-size: 0.7rem;
}
.Comment__ReplyTo {
  border-left: 2px red solid;
  padding-left: 5px;
  margin: 5px 0 5px 5px;
  transform: scale(0.9) translateX(0px);
}
.Comment__bb_green {
  border-color: green;
}
.Comment__bb_red {
  border-color: red;
}

.MyComment:after {
  content: "👤";
  display: block;
  height: 0;
  border: 6px solid transparent; /* Прозрачные границы */
  border-left: 8px solid #555; /* Добавляем треугольник */
  transform: translateY(15px);
}

.AlienComment::before {
  content: "👨‍🔧";
  display: block;
  height: 0;
  border: 6px solid transparent; /* Прозрачные границы */
  border-right: 8px solid #555; /* Добавляем треугольник */
  transform: translateY(15px);
}

@keyframes drawerBack {
  from {
    right: 0px;
  }
  to {
    right: -400;
  }
}`, "",{"version":3,"sources":["webpack://./src/UI/Comment/Comment.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,6BAAA;EACA,aAAA;EACA,aAAA;EACA,8BAAA;AAEF;AADC;EACC,gBAAA;AAGF;AADC;EACC,iBAAA;AAGF;AADC;EACC,0BAAA;EACA,iBAAA;EACA,qBAAA;EACA,qCAAA;AAGF;AADA;EACG,mBAAA;AAGH;AADA;EACG,iBAAA;AAGH;;AACE;EACC,aAAA;EACA,cAAA;EACA,SAAA;EACA,6BAAA,EAAA,uBAAA;EACD,2BAAA,EAAA,0BAAA;EACA,2BAAA;AAEF;;AAGE;EACE,gBAAA;EACA,cAAA;EACA,SAAA;EACA,6BAAA,EAAA,uBAAA;EACD,4BAAA,EAAA,0BAAA;EACA,2BAAA;AAAH;;AAIA;EACE;IACE,UAAA;EADF;EAIA;IACE,WAAA;EAFF;AACF","sourcesContent":[".Comment {\n  width: 80%;\n  max-width: 425px;\n  background: #555;\n  padding: 5px 10px;\n  border-radius: 5px;\n  border-bottom: 3px solid #333;\n  margin: 5px 0;\n  display: flex; \n  justify-content: space-between;\n &__Actions {\n  min-width: 125px;\n }\n &__Time {\n  font-size: 0.7rem;\n }\n &__ReplyTo {\n  border-left: 2px red solid;\n  padding-left: 5px;\n  margin: 5px 0 5px 5px;\n  transform: scale(0.9) translateX(0px);\n}\n&__bb_green {\n   border-color: green;\n }\n&__bb_red {\n   border-color: red;\n }\n}\n.MyComment {\n  &:after {\n   content: '👤';\n   display: block;\n   height: 0;\n   border: 6px solid transparent; /* Прозрачные границы */\n  border-left: 8px solid #555; /* Добавляем треугольник */\n  transform: translateY(15px);\n }\n}\n\n.AlienComment {\n  &::before {\n    content: '👨‍🔧';\n    display: block;\n    height: 0;\n    border: 6px solid transparent; /* Прозрачные границы */\n   border-right: 8px solid #555; /* Добавляем треугольник */\n   transform: translateY(15px);\n  }\n}\n\n@keyframes drawerBack {\n  from {\n    right: 0px\n  }\n\n  to {\n    right: -400\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
