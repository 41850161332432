import axios from 'axios'
import { compose, concat, map, prop, reduce } from 'ramda'

import { splitOnChanks } from '../../utils'
import { IIndictionServiceRespose } from './useReportService'

const IDS_CHANCKS_COUNT = 500

export const reduceIndicationsFormChanks = async (
  ids: string[],
  startDate: string,
  endDate: string
) => {
  const idsChanks = splitOnChanks(ids, IDS_CHANCKS_COUNT)
  const indications = []
  for (const ids of idsChanks) {
    const request = await axios.get<IIndictionServiceRespose>(
      'http://app.teplo-balans.ru/api/indications/indications',
      {
        params: {
          ids,
          startDate,
          endDate,
        },
      }
    )

    indications.push(...request.data.recordset)
  }
  return indications
}

const getChannels = prop('channels')
const getChannelsIds = map(prop('ID'))
const getIds = compose(getChannelsIds, getChannels)

export const getChannelIds = reduce(
  (ids, node) => concat(ids, getIds(node)),
  []
)
