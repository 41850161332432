import { ConfigProvider, theme } from 'antd'
import clsx from 'clsx'
import React from 'react'
import { hot } from 'react-hot-loader'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Redirect, Route, Switch } from 'react-router-dom'

import AuthRoute from './components/AuthRoute'
import { Role } from './const'
import { Login, Serviceman, Web } from './pages'
import { AppRoute } from './router'
import { useAuthService } from './services/auth.service'

function App(): JSX.Element {
  const queryClient = new QueryClient()
  const { isRole, user } = useAuthService()
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          algorithm: [
            theme.darkAlgorithm,
            // theme.compactAlgorithm
          ],
          components: {
            Button: {
              colorPrimary: 'orange',
              controlHeight: 30,
              fontWeight: 700,
            },
          },
          token: {
            // Seed Toke
            colorPrimary: 'orange',
            borderRadius: 6,
          },
        }}
      >
        <div
          className={clsx('App', {
            Serviceman: isRole(Role.Serviceman),
          })}
        >
          <div id="drawer"></div>
          <Switch>
            <Route path={AppRoute.Login} exact>
              <Login />
            </Route>
            {!user && <Redirect to={AppRoute.Login} />}
            <AuthRoute
              path={AppRoute.Serviceman}
              view={Serviceman}
              access={[Role.Manager, Role.Serviceman]}
            />
            {isRole(Role.Serviceman) && (
              <Redirect to={AppRoute.ServicemanRepairs} />
            )}
            <AuthRoute path={'/'} view={Web} access={[Role.Manager]} />:
          </Switch>
        </div>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default hot(module)(App)
