import { andThen, compose, partialRight } from 'ramda'
import { useMemo, useState } from 'react'

import { useExcelService } from '../../../services/excel.service/useExcelService'
import { useFileSaverService } from '../../../services/FileSaver.service'
import { useGetIndicationService } from '../../../services/indication.service'
import { useNodeService } from '../../../services/Node.service'
import { Period } from '../../../store/reducers/indications'
import {
  addExtention,
  createTable,
  getChannelIdToPipelineIdMap,
} from '../../indications/hooks'

export const useCreateCard = () => {
  const { indications, isFetching } = useGetIndicationService()
  const { node } = useNodeService()
  const { createExcelFromBuffer } = useExcelService()
  const { saveArrayBuffer } = useFileSaverService()
  const [loading, setLoading] = useState<boolean>(false)

  const channelMap = useMemo(
    () => getChannelIdToPipelineIdMap(node?.channels || []),
    [node?.channels]
  )

  const createExcelCard = compose(
    andThen(partialRight(saveArrayBuffer, [addExtention(node?.name)])),
    createExcelFromBuffer
  )

  const downloadCard = async (period: Period) => {
    if (!node) return

    setLoading(true)
    createExcelCard({
      node,
      indications: createTable(indications || [], channelMap),
      period,
    })
    setLoading(false)
  }

  return {
    downloadCard,
    loading,
  }
}
