import clsx from 'clsx'
import { equals } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import Button from '../Button'
import Title from '../Title'
import { ITitleProps } from '../Title/Title'
import * as styles from './Modal.module.scss'
export interface IModalProps {
  closeModal: () => void
  onClose?: boolean
  title?: string
  footer?: (closeModal: () => void) => ITitleProps['actions'] | boolean
  type?: 'drawer' | 'sheet'
  isLoading?: boolean
  onOk?: () => void
  onCancel?: () => void
  okText?: string
  okDisabled?: boolean
  okLoading?: boolean
  cancelDisabled?: boolean
}
export enum ModalType {
  drawer = 'drawer',
  sheet = 'sheet',
}

const ANIMATION_DURATION: number = parseInt(styles.animationDuration)

export const Modal: React.FC<IModalProps> = ({
  children,
  closeModal,
  title,
  footer,
  type,
  isLoading,
  onCancel,
  onOk,
  onClose,
  okText,
  okDisabled,
  okLoading,
  cancelDisabled,
}) => {
  const wrapperRef = useRef(null)
  const ref: any = wrapperRef.current
  const portal: HTMLElement | null = document.getElementById('drawer')
  const [isUnmounting, setIsUnmountiong] = useState<boolean>(false)
  const [isMounting, setIsMountiong] = useState<boolean>(true)

  const closeModalWithAnimation = () => {
    setIsUnmountiong(true)
    if (closeModal) {
      setTimeout(() => closeModal(), ANIMATION_DURATION)
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setTimeout(() => setIsMountiong(false), ANIMATION_DURATION)
    return () => {
      document.body.style.removeProperty('overflow')
    }
  }, [])

  const isType = equals(type)

  const modal = (
    <div
      className={clsx(styles.modal, {
        [styles.unmountAnimate]: isUnmounting,
        [styles.mountAnimate]: isMounting,
      })}
    >
      <div
        className={clsx({
          [styles.form]: !isType(ModalType.drawer) && !isType(ModalType.sheet),
          [styles.drawer]: isType(ModalType.drawer),
          [styles.sheet]: isType(ModalType.sheet),
          [styles.unmountAnimate]: isUnmounting,
          [styles.mountAnimate]: isMounting,
        })}
      >
        <Title
          title={title}
          actions={
            onclose && <Button onClick={closeModalWithAnimation} title="✕" />
          }
        />

        <div className={styles.content}>{children}</div>

        <Title
          border="top"
          actions={[
            <Button
              title="Закрыть"
              onClick={closeModalWithAnimation}
              key="cancel"
              disabled={cancelDisabled}
            />,
            <Button
              title={okText || 'Отправить'}
              loading={okLoading}
              disabled={okDisabled}
              onClick={onOk}
              key="sent"
            />,
          ]}
        />
      </div>
    </div>
  )

  if (!portal) {
    console.warn(`portal is not founded`)
    return null
  }

  return ReactDOM.createPortal(modal, portal)
}
