import React from 'react'
import { useSelector } from 'react-redux'

import { reportPeriodSelector } from '../../../store/reducers/indications'
import { Button, Table } from '../../../UI'
import { useDrawer } from '../../../UI/Drawer/Drawer'
import ReportDatePeriod from '../../Excel'
import { useCreateCard } from '../../Excel/hooks'
import { useGetHeaderAndDataAndLoading } from '../hooks/useGetHeaderAndDataAndLoading'
import { ChannelsForm } from '.'

export const Indications: React.FC = () => {
  const { openDrawer, drawer } = useDrawer({
    content: <ChannelsForm />,
    title: 'Каналы',
  })

  const period = useSelector(reportPeriodSelector)

  const { refetch, ...rowsAndHeaderAndLoading } =
    useGetHeaderAndDataAndLoading()

  const { downloadCard, loading } = useCreateCard()
  return (
    <>
      <Table
        {...rowsAndHeaderAndLoading}
        rowId={'date'}
        actions={[
          <Button key={'channels'} title={'Каналы'} onClick={openDrawer} />,
          <ReportDatePeriod key={'excel'} refetch={refetch} />,
          <Button
            title={'Excel'}
            loading={loading}
            onClick={() => downloadCard(period)}
          />,
        ]}
        title="Показания приборов"
      />
      {drawer}
    </>
  )
}
