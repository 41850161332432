// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Avatar {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.Photo {
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: rgb(71.25, 71.25, 71.25);
  text-align: center;
  line-height: 26px;
}
.Photo + p {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/UI/Avatar/Avatar.scss","webpack://./src/scss/const.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,aAAA;AADJ;;AAGA;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0CCJc;EDKd,kBAAA;EACA,iBAAA;AAAJ;AAEI;EACI,iBAAA;AAAR","sourcesContent":["@import '../../scss/const.scss';\n\n.Avatar {\n    display: flex;\n    align-items: center;\n    margin: 0 5px;\n}\n.Photo {\n    display: block;\n    width: 26px;\n    height: 26px;\n    border-radius: 50%;\n    background-color: $secondary-color;\n    text-align: center;\n    line-height: 26px;\n    \n    &+p{\n        margin-left: 10px;\n    }\n}","$theme: dark;\n$min-height: 50px;\n$title-height: 50px;\n\n$base-color: #212121;\n$active-color: lighten($base-color, 10%);\n$hover-color: lighten($base-color, 20%);\n\n$secondary-color: lighten($base-color, 15%);\n\n$accent-color: lighten($base-color, 15%);\n\n// $red: #c6538c;\n$red: #e12526;\n$font-color: #dadada;\n$font-color--active: darken($font-color, 10%);\n$font-color--hover: darken($font-color, 5%);\n//-------------\n// $base-color: #fff;\n// $active-color: #F4F5F7;\n// $hover-color: #EBECF0;\n\n// $secondary-color: rgb(244, 245, 247);\n// $accent-color: darken($base-color, 15%);\n\n// $font-color: #172B4D;\n// $font-color--active: #0053cc;\n// $font-color--hover: #172B4D;\n//--------\n$bckClr: $base-color;\n\n$footer-height: 22px;\n\n$mobile: 'max-width: 425px';\n$mobileMenu-height: 50px;\n$border: 1px solid $active-color;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
