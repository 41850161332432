import { compose, find, join, partialRight, prop, props } from 'ramda'
import React, { useMemo } from 'react'
import { Route } from 'react-router-dom'

import { useGetRepairsByStatusAndUserQuery } from '../../../../api/endpoints.axios/repairs'
import { IRepairDto } from '../../../../api/endpoints.axios/types'
import { toFormatYyyyMmDd } from '../../../../features/indications/hooks'
import { Location, RepairStatus } from '../../../../models'
import { AppRoute } from '../../../../router'
import { List, ListItem } from '../../../../UI/List'
import { ModalForm } from './Modal'

export const getLocation = compose<[IRepairDto], Location, string[], string>(
  join(', '),
  props(['access', 'location']),
  prop('location')
)

const RepairList: React.FC = () => {
  // const { data: repairs, isFetching } = useGetRepairsQuery()

  const { data: repairs, isFetching } = useGetRepairsByStatusAndUserQuery(
    RepairStatus.New
  )

  const getRepairById = partialRight(find, [repairs])

  const contennt = useMemo(
    () =>
      repairs?.map((repair) => (
        <ListItem
          key={repair.id}
          title={
            repair?.node?.name +
            ', ' +
            toFormatYyyyMmDd(new Date(repair.createdAt))
          }
          subTitle={getLocation(repair)}
          text={repair.defect}
          to={`/serviceman/repairs/${repair.id}`}
        />
      )),
    [repairs]
  )

  if (isFetching) {
    return 'sdfsd'
  }

  return (
    <>
      <Route path={AppRoute.RepairById}>
        <ModalForm getRepairById={getRepairById} />
      </Route>
      <List content={contennt || []} />
    </>
  )
}

export default RepairList
