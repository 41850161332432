import { isEmpty } from 'ramda'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  IGetIndicationsArg,
  useGetIndicationsQuery,
} from '../api/endpoints.axios/indication'
import { useGetChannelsByIdQuery } from '../api/endpoints.graphql/channel'
import {
  getEndDate,
  getIds,
  getStartDate,
  toFormatYyyyMmDd,
} from '../features/indications/hooks'
import { IRouterParams } from '../router'
import { reportPeriodSelector } from '../store/reducers/indications'

export const useGetIndicationService = () => {
  const { nodeId } = useParams<IRouterParams>()
  const period = useSelector(reportPeriodSelector)

  const { data: channels } = useGetChannelsByIdQuery(
    { nodeId },
    { skip: !nodeId }
  )

  const params: IGetIndicationsArg = useMemo(
    () => ({
      ids: getIds(channels),
      startDate: toFormatYyyyMmDd(new Date(getStartDate(period))),
      endDate: toFormatYyyyMmDd(new Date(getEndDate(period))),
    }),
    [channels, period]
  )

  const {
    data: indications,
    isFetching,
    refetch,
  } = useGetIndicationsQuery(params, {
    skip: isEmpty(params?.ids),
  })

  return {
    indications,
    isFetching,
    channels,
    refetch,
  }
}
