import './Login.scss'

import React from 'react'

import logo from '../../img/logo.svg'
import { Layout } from '../../UI'
import { Content } from '../../UI/App'
import { LoginForm } from './LoginForm'

const Login: React.FC = () => {
  return (
    <Layout className="Login">
      <Content>
        <div className="Login__logo">
          <img src={logo} className="App-logo" alt="logo" height={130} />
        </div>
        <h2 className="Login__title">ТЕПЛОБАЛАНС</h2>
        <div className="Login__form">
          <LoginForm />
        </div>
      </Content>
    </Layout>
  )
}

export default Login
