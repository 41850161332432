import './Nav.scss'

import React from 'react'

interface Props {
  content: JSX.Element[]
}

const Nav: React.FC<Props> = ({ content }) => {
  return <ul className={`Nav`}>{content}</ul>
}

export default Nav
