import React from 'react'

import Loader from '../Loader/Loader'
import Section from '../Section'
import Title from '../Title'
import * as styles from './Table.module.scss'

export interface TableHeader {
  name: string
  value: string
  type?: string
}

export interface ITableProps {
  header: TableHeader[]
  rows?: any[]
  loading?: boolean
  actions?: JSX.Element | JSX.Element[]
  action?: (item: any) => JSX.Element
  selected?: (items: any[]) => void
  rowId: string
  title?: string | JSX.Element
}

const Table: React.FC<ITableProps> = ({
  header,
  rows,
  loading,
  actions,
  action,
  selected,
  rowId,
  title,
}) => {
  const EmptyRow = () => (
    <tr>
      <td
        className={styles.td}
        colSpan={actions ? ++header.length : header.length}
        style={{ textAlign: 'center' }}
      >
        Нет данных
      </td>
    </tr>
  )

  const LoadingRow = () => (
    <tr className={styles.loader}>
      <td className={styles.td}>
        <Loader />
      </td>
    </tr>
  )

  const renderRow = () => {
    if (rows?.length) {
      return rows.map((row, number) => {
        return (
          <tr key={row[rowId]} className={styles.row}>
            {selected && (
              <td className={styles.td}>
                <input
                  type="checkbox"
                  checked={rows[number]['selected']}
                  onChange={(e) => {
                    const target = e.target
                    rows[number]['selected'] = target.checked
                    selected(rows.filter((id) => id.selected))
                  }}
                />
              </td>
            )}

            {header.map((td) => {
              const path = td?.value?.split('.')

              if (!path?.length) return null

              let value = row
              for (let i = 0; i < path.length; i++) {
                if (value) value = value[path[i]]
              }

              return (
                <td key={td.value} className={styles.td}>
                  {td.type === 'date'
                    ? new Date(+value).toLocaleDateString()
                    : value}
                </td>
              )
            })}

            {renderActions(row)}
          </tr>
        )
      })
    }

    return EmptyRow()
  }

  const renderActions = (id: number) => {
    if (action) return <td className={styles.td}>{action(id)}</td>
  }

  return (
    <>
      <Title title={title} actions={actions} />
      <Section>
        <table className={styles.table}>
          <thead>
            <tr className={styles.header}>
              {selected && <th></th>}
              {header.map((th) => (
                <th className={styles.td} key={th.name}>
                  {th.name}
                </th>
              ))}
              {action && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {renderRow()}
            {loading && LoadingRow()}
          </tbody>
        </table>
      </Section>
    </>
  )
}

export default Table
