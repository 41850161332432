export const getRepairById = {
  params: { url: '/repair/5166' },
  response: {
    id: 5166,
    defect: 'Тэкон не на связи с 17.06',
    comment: null,
    nodeId: 975,
    updatedBy: null,
    createdBy: 4,
    status: 2,
    createdAt: '2022-06-21T11:07:39.000Z',
    updatedAt: '2022-08-06T04:41:37.000Z',
    deleted: false,
    NODE_ID: 975,
    CREATED_BY: 4,
    user: {
      id: 4,
      login: 'drokin_s',
      password: 'Drokin1',
      email: 'drokin_s',
      ROLE_ID: 1,
      PROFILE_ID: 7,
      profile: {
        id: 7,
        name: 'Сергей Дрокин',
      },
    },
    comments: [
      {
        id: 28155,
        defectId: 5166,
        comment: 'Тэкон не на связи с 17.06',
        userId: 4,
        createdAt: '2022-06-21T11:07:39.000Z',
        updatedAt: '2022-06-21T11:07:39.000Z',
        DEFECT_ID: 5166,
        USER_ID: 4,
        user: {
          id: 4,
          login: 'drokin_s',
          password: 'Drokin1',
          email: 'drokin_s',
          ROLE_ID: 1,
          PROFILE_ID: 7,
          profile: {
            id: 7,
            name: 'Сергей Дрокин',
          },
        },
      },
      {
        id: 28169,
        defectId: 5166,
        comment: 'Т-19 в ремонт',
        userId: 11,
        createdAt: '2022-06-23T05:02:31.000Z',
        updatedAt: '2022-06-23T05:02:31.000Z',
        DEFECT_ID: 5166,
        USER_ID: 11,
        user: {
          id: 11,
          login: 'dolgih_a',
          password: 'Dolgih1',
          email: 'dolgih_a',
          ROLE_ID: 2,
          PROFILE_ID: 11,
          profile: {
            id: 11,
            name: 'Долгих Александр',
          },
        },
      },
    ],
  },
}
