import { QueryBuilder } from '@serafim_v/gql-query-builder'

import { Provider } from '../../models'
import { grphqlApi } from '..'

export type IGetProvidersResponse = { providers: Provider[] }
export type IGetProvidersArgs = void

export const providerEntity = new QueryBuilder<Provider>('providers')
  .addProperty('id')
  .addProperty('name')

// export const query = new QueryBuilder<Provider>(providerEntity).create()

export const providerApi = grphqlApi.injectEndpoints({
  endpoints: (build) => ({
    getProviders: build.query<Provider[], IGetProvidersArgs>({
      query: () => ({
        document: providerEntity.execute(),
      }),
      transformResponse: (response: IGetProvidersResponse) =>
        response.providers,
    }),
  }),
})

export const { useGetProvidersQuery } = providerApi
