import './Card.scss'

import clsx from 'clsx'
import React from 'react'

import { Title } from '..'

export interface ICardProps {
  title?: { title: string; actions?: JSX.Element }
  content?: JSX.Element | string | JSX.Element[]
  empty?: boolean
  width?: number | string
  height?: number | string
  actions?: JSX.Element[] | JSX.Element
  column?: boolean
}

const Card: React.FC<ICardProps> = ({
  title,
  content,
  empty,
  width,
  actions,
  column,
  height,
}) => {
  const STYLE = {
    width,
    height,
  }

  return (
    <div
      className={clsx([
        'Card',
        {
          'Card--empty': empty,
          'Card--column': column,
          'Card--row': !column,
        },
      ])}
      style={STYLE}
    >
      {title && <Title {...title} className="Card__title" />}

      <>
        <div className="Card__Content">{content}</div>
        {actions ? <div className="Card__Footer">{actions}</div> : null}
      </>
    </div>
  )
}

export default Card
