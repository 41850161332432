import { useMemo } from 'react'

import { useGetIndicationService } from '../../../services/indication.service'
import { createTable, getChannelIdToNameMap, getHeaderWithDate } from './utils'

export function useGetHeaderAndDataAndLoading() {
  const { indications, isFetching, channels, refetch } =
    useGetIndicationService()

  const channelMap = useMemo(
    () => getChannelIdToNameMap(channels || []),
    [channels]
  )

  return {
    header: getHeaderWithDate(channels || []),
    rows: createTable(indications || [], channelMap),
    loading: isFetching,
    refetch,
  }
}
