// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Report-module__Report-cQ8Bu .Content {
  width: 1000px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Report/Report.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,cAAA;AAAJ","sourcesContent":[".Report {\n  :global(.Content) {\n    width: 1000px;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var report = `Report-module__Report-cQ8Bu`;
export default ___CSS_LOADER_EXPORT___;
