import React, { useMemo } from 'react'

import { useGetRepairsByStatusAndUserQuery } from '../../../api/endpoints.axios/repairs'
import { toFormatYyyyMmDd } from '../../../features/indications/hooks'
import { RepairStatus } from '../../../models'
import { List, ListItem } from '../../../UI'

export const Test: React.FC = () => {
  const { data: repairs } = useGetRepairsByStatusAndUserQuery(
    RepairStatus.Progress
  )

  const contennt = useMemo(
    () =>
      repairs?.map((repair) => (
        <ListItem
          key={repair.id}
          title={repair?.node?.name + ', ' + repair?.node?.geu}
          subTitle={toFormatYyyyMmDd(new Date(repair.createdAt))}
          text={repair.defect}
        />
      )),
    [repairs]
  )

  return <List content={contennt || []} />
}
