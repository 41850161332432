import { Workbook } from 'exceljs'
import { pipe, tap } from 'ramda'

import { IGetNodeByIdWithChannelsAndSchemaRes } from '../../api/endpoints.axios/nodes'
import { ITableValue } from '../../features/indications/hooks'
import { Period } from '../../store/reducers/indications'
import { useFileReader } from '../FileSaver.service'
import template from './tmpl.xlsx'
import {
  bindFn,
  calculateDisbalans,
  clearTotalColumns,
  fillFormuls,
  fillInIndicationsData,
  formatedDisbalans,
  heatOff,
  hideAllColumnsAndRows,
  hideAllDisbalans,
  hideBoilerTotal,
  hideRowsQotopAndQgvs,
  renderHeader,
  rewriteQotopIfNeed,
  setPageOptions,
  showPeriod,
  showPipelines,
  useSummerCardIfNeed,
} from './utils'

const fillExcelCard = pipe(
  tap(bindFn(setPageOptions)),
  tap(bindFn(renderHeader)),
  tap(bindFn(hideAllColumnsAndRows)),
  tap(bindFn(hideBoilerTotal)),
  tap(bindFn(hideAllDisbalans)),
  tap(bindFn(clearTotalColumns)),
  tap(bindFn(showPipelines)),
  tap(bindFn(showPeriod)),
  tap(bindFn(fillFormuls)), //могут возникать ошибки при изменении порядка
  tap(bindFn(fillInIndicationsData)), //должно быть после fillFormuls, тк данные имеют преоритет перед формулами
  tap(bindFn(rewriteQotopIfNeed)),
  tap(bindFn(heatOff)),
  tap(bindFn(calculateDisbalans)),
  tap(bindFn(formatedDisbalans)),
  tap(bindFn(hideRowsQotopAndQgvs)),
  tap(bindFn(useSummerCardIfNeed))
)

export type IExcelCardParams = {
  node: IGetNodeByIdWithChannelsAndSchemaRes
  indications: ITableValue[]
  period: Period
}

export const useExcelService = () => {
  const { readFileToBuffer } = useFileReader()

  const createExcelFromBuffer = async ({
    node,
    indications,
    period,
  }: IExcelCardParams) => {
    const workbook = new Workbook()
    await workbook.xlsx.load(await readFileToBuffer(template))
    workbook.calcProperties.fullCalcOnLoad = true
    const worksheet = workbook.getWorksheet(1)
    const params = { worksheet, node, indications, period }
    try {
      fillExcelCard(params)
    } catch (e) {
      console.error(e)
    }
    const buffer = await workbook.xlsx.writeBuffer()
    return buffer
  }

  return { createExcelFromBuffer }
}
