import './Section.scss'

import { clsx } from 'clsx'
import React from 'react'

interface Props {
  flex?: boolean
  children: JSX.Element | JSX.Element[]
}

const Section: React.FC<Props> = ({ children, flex }) => {
  return (
    <div
      className={clsx('Section', {
        'Section--flex': flex,
      })}
    >
      {children}
    </div>
  )
}

export default Section
