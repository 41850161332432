import { path, prop } from 'ramda'
import React, { useMemo } from 'react'

import { useGetNodesQuery } from '../../../api/endpoints.axios/nodes'
import { Node } from '../../../models'
import { List, ListItem } from '../../../UI'

const getNodeId = prop('id')
const getRepairId = path(['repairs', 0, 'id'])

const getUrl = (node: Node): string =>
  `/node/${getNodeId(node)}/repair/${getRepairId(node)}`

export default function RepairList() {
  const { data: nodes, isFetching } = useGetNodesQuery()

  const addressList = useMemo(() => {
    return (
      nodes?.map((node) => (
        <ListItem
          title={node?.name}
          subTitle={node.repairs?.[0]?.defect}
          to={getUrl(node)}
          text={node?.repairs?.[0]?.comments?.[1]?.comment}
          key={node?.id}
          actions={node?.repairs.length > 1 ? <>1</> : undefined}
        />
      )) || []
    )
  }, [nodes])

  return <List filter="title" content={addressList} loading={isFetching} />
}
