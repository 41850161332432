import React, { memo } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'

import { FormItem } from '../../UI'

interface IInputProps extends UseControllerProps {
  placeholder?: string
  label?: string
  type?: 'text' | 'date'
}

export const TextField: React.FC<IInputProps> = (props) => {
  const { field, fieldState } = useController(props)

  return (
    <FormItem hint={fieldState.error?.message} label={props.label}>
      <input
        {...field}
        type={props.type || 'text'}
        placeholder={props.placeholder}
        className="Input"
      />
    </FormItem>
  )
}
export const TextFieldMemo = memo(TextField)
