import { add, format } from 'date-fns'
import { Worksheet } from 'exceljs'
import { compose, forEach, intersection, partialRight, path, prop } from 'ramda'

import { IPipelineColumn } from '../../const/types'
import {
  DATE_FORMAT,
  DISBALANS_COLUMNS_NUMBERS,
  DisbalansValue,
  MONTHS,
  TOTAL_COLUMNS,
  XlCol,
} from './consts'
import { IExcelCardParams } from './useExcelService'

function hideAllColumns(this: Worksheet) {
  for (let col = 2; col < 78; col++) {
    this.getColumn(col).hidden = true
  }
}

function hideAllRows(this: Worksheet) {
  for (let row = 14; row < 45; row++) {
    this.getRow(row).hidden = true
  }
}

export function hideAllColumnsAndRows(this: Worksheet) {
  hideAllColumns.call(this)
  hideAllRows.call(this)
}

export function hideBoilerTotal(this: Worksheet) {
  this.getColumn(80).hidden = true
  this.getRow(49).hidden = true
}

export function hideAllDisbalans(this: Worksheet) {
  for (const col of DISBALANS_COLUMNS_NUMBERS) {
    this.getColumn(col).hidden = true
  }
}

const getValue = (pipelineId: string, value: string): number | string =>
  pipelineId === 'date' ? value : Number(value)

const getColumnNthByKey = (pipelineId: string): number => {
  return pipelineId === 'date' ? 1 : Number(pipelineId)
}

function showColumnByNth(this: Worksheet, columnNth: number): void {
  this.getColumn(columnNth).hidden = false
}

const showColumn = compose(showColumnByNth, getColumnNthByKey)

function showRow(this: Worksheet, rowNth: number): void {
  this.getRow(rowNth + 14).hidden = false
}

function fillInWorkedTime(this: Worksheet, rowNth: number): void {
  this.getRow(rowNth + 14).getCell('CE').value = 24
}

export function showPipelines(this: Worksheet, { node }: IExcelCardParams) {
  node?.channels
    .map(prop('pipelineId'))
    .filter(Boolean)
    .forEach(showColumnByNth.bind(this))
}

export function showPeriod(this: Worksheet, { period }: IExcelCardParams) {
  let startDate = new Date(period.startDate)
  let index = 0
  while (startDate <= new Date(period.endDate)) {
    this.getRow(index + 14).getCell(1).value = format(startDate, DATE_FORMAT)
    fillInWorkedTime.call(this, index)
    showRow.call(this, index++)
    startDate = add(startDate, { days: 1 })
  }
}

export function fillInIndicationsData(
  this: Worksheet,
  { indications }: IExcelCardParams
): void {
  Object.keys(indications[0]).forEach((pipelineId: string) => {
    const cell = getColumnNthByKey(pipelineId)

    for (let index = 14; index < 45; index++) {
      this.getRow(index).getCell(cell).value = null
    }
  })

  indications.forEach((row, index: number) => {
    Object.keys(row).forEach((pipelineId: string) => {
      const value = getValue(pipelineId, row[pipelineId])
      const cell = getColumnNthByKey(pipelineId)
      this.getRow(index + 14).getCell(cell).value = value
    })
  })
}

function setValuesOColumn(this: Worksheet, columnId: number) {
  for (let row = 14; row < 45; row++) {
    this.getRow(row).getCell(columnId).value = 0
  }
}

export const clearTotalColumns = compose(
  forEach(setValuesOColumn),
  intersection(TOTAL_COLUMNS),
  path(['node', 'channels'])
)

export function bindFn(fn: () => void) {
  return ({
    worksheet,
    ...params
  }: { worksheet: Worksheet } & IExcelCardParams) => fn.call(worksheet, params)
}

const schemeIs = (scheme: IExcelCardParams['node']['scheme'], param: string) =>
  scheme.schemeActual.includes(param)

const isOpenScheme = partialRight(schemeIs, ['открытая'])
const isCloseScheme = partialRight(schemeIs, ['закрытая'])
const is2pipeScheme = partialRight(schemeIs, ['2-х'])
const is3pipeScheme = partialRight(schemeIs, ['3-х'])
const is4pipeScheme = partialRight(schemeIs, ['4-х'])
const isCircScheme = partialRight(schemeIs, ['цирк'])
const isBoilerScheme = partialRight(schemeIs, ['бойл'])

/** Только для закрытой схемы */
export function rewriteQotopIfNeed(this: Worksheet) {
  if (this.getCell('J14').value) {
    calculateColumn.call(this, XlCol.Qotop, `J14+S14+AK14`)
  }
}

export function fillFormuls(this: Worksheet, { node }: IExcelCardParams) {
  if (!node?.scheme?.scheme) return

  /** Qgvs */
  calculateColumn.call(this, XlCol.Qgvs, FORMULA_Q_GVS)
  /** Ggvs */
  calculateColumn.call(this, XlCol.Ggvs, FORMULA_G_GVS)

  /** Qotop */
  if (isOpenScheme(node.scheme)) {
    calculateColumn.call(this, XlCol.Qpotr, FORMULA_Q_OTOP)
    calculateColumn.call(this, XlCol.Qotop, `BZ14-CC14`)
  }

  /** Qpotr */
  if (isCloseScheme(node.scheme)) {
    calculateColumn.call(this, XlCol.Qotop, FORMULA_Q_OTOP)
    calculateColumn.call(this, XlCol.Qpotr, `CA14+CC14`)
  }
}

const FORMULA_Q_OTOP = `(E14+N14+W14+AA14+AF14)-(I14+R14+AJ14)`
const FORMULA_Q_GVS = `BE14-BI14+BM14-BQ14+BU14-BY14`
const FORMULA_G_GVS = `BD14-BH14+BL14-BP14+BT14-BX14`

function getRangeColumn(col: IPipelineColumn) {
  return `${col}14:${col}44`
}

function calculateColumn(
  this: Worksheet,
  col: IPipelineColumn,
  formula: string
): void {
  this.fillFormula(getRangeColumn(col), formula)
}

// const calculateQotop = partialRight(calculateColumn, [FORMULA_Q_OTOP])

function setConsumerWithContract(
  this: Worksheet,
  consumerWithContract: string
) {
  this.getCell('A2').value = consumerWithContract
}

const getConsumerWithContract = (node: IExcelCardParams['node']) =>
  [node.consumer.name, `Договор №${node.contract}`].join(' ')

function setAndformatNodeName(this: Worksheet, node: IExcelCardParams['node']) {
  this.getCell('A3').value = {
    richText: [
      { text: String(this.getCell('A3')?.value) },
      { font: { bold: true, size: 16 }, text: node.name },
    ],
  }
}

function setAndFormateData(this: Worksheet): void {
  this.getCell('A1').value = {
    richText: [
      { text: String(this.getCell('A1').value) },
      {
        font: { bold: true, size: 12 },
        text: MONTHS[new Date().getMonth()] + ' ' + new Date().getFullYear(),
      },
    ],
  }
}

export function renderHeader(
  this: Worksheet,
  { node }: IExcelCardParams
): void {
  try {
    setAndFormateData.call(this)
    setConsumerWithContract.call(this, getConsumerWithContract(node))
    setAndformatNodeName.call(this, node)
    if (node.scheme) {
      this.getRow(5).getCell(
        1
      ).value = `Qотоп = ${node.scheme.heatLoad1} Гкал/ч, ` //нагрузка
      this.getRow(5).getCell(1).value += `Gгвс = ${node.scheme.heatLoad2} т/ч, ` //нагрузка
      this.getRow(5).getCell(
        1
      ).value += `Температурный график - ${node.scheme.chartHeating} ` //нагрузка
      this.getRow(5).getCell(1).value += `(${node.scheme.chartHws})` //нагрузка
      this.getRow(5).getCell(1).value += `, txu=${node.scheme.ths}°C` //нагрузка
      this.getRow(7).getCell(1).value = `${node.scheme.scheme}` //схема
      this.getRow(9).getCell(1).value = `${node.scheme.formulaWin}` //зима
      this.getRow(10).getCell(1).value = `${node.scheme.formulaSum}` //лето
    }
  } catch {
    throw new Error('Error in renderHeader function')
  }
}

export function setPageOptions(this: Worksheet) {
  this.pageSetup = {
    paperSize: 9,
    orientation: 'landscape',
    scale: 70,
    printArea: 'A1:CF55',
    fitToPage: true,
  }
}

export function heatOff(this: Worksheet, { node }: IExcelCardParams) {
  if (!node?.scheme) return

  const heatOffDate = '2021-5-12'
  // if (!heatOff) return
  for (let row = 14; row < 45; row++) {
    if (
      this.getCell('A' + row).value ==
      heatOffDate.split('-').reverse().join('.')
    ) {
      this.fillFormula('B' + row + ':BA44', '0')

      /** Открытая схема */
      if (node?.scheme?.scheme?.search(/открытая/) != -1) {
        this.fillFormula('BZ' + row + ':BZ44', '=CC' + row)
        this.fillFormula('CA' + row + ':CA44', '0')
      }
    }
  }
}

export function useSummerCardIfNeed(
  this: Worksheet,
  { summer }: IExcelCardParams
) {
  if (!summer) return

  for (let i = 2; i < 54; i++) {
    this.getColumn(i).hidden = true
  }

  ;[78, 79].forEach((i) => {
    this.getColumn(i).hidden = true
  })
  this.getRow(47).hidden = true
  this.getRow(48).hidden = true
  this.getColumn(84).width = 40
}

function getReducersPipelines(this: Worksheet, row: number) {
  const sumColumnsValues = (columns: string[]) =>
    columns.reduce((sum, i) => {
      const { value } = this.getCell(i + row)
      return sum + (value ? +value : 0)
    }, 0)

  const po = sumColumnsValues(['D', 'M', 'V', 'Z', 'AE'])
  const oo = sumColumnsValues(['H', 'Q', 'AI'])
  const gv = sumColumnsValues(['BD', 'BL', 'BT'])
  const cr = sumColumnsValues(['BH', 'BP', 'BX'])
  const Qgv = sumColumnsValues(['BE', 'BM', 'BU'])
  const Qcr = sumColumnsValues(['BI', 'BQ', 'BY'])
  return { po, oo, gv, cr, Qgv, Qcr }
}

export function calculateDisbalans(
  this: Worksheet,
  { node }: IExcelCardParams
) {
  if (!node?.scheme?.scheme) return

  if (is2pipeScheme(node?.scheme) && !isCircScheme(node?.scheme)) {
    this.fillFormula(
      'CH14:CH44',
      'ABS(((D14+M14+V14+Z14+AE14)-(H14+Q14+AI14)-(BD14+BL14+BT14))/(D14+M14+V14+Z14+AE14)*100)',
      (row, col) => {
        const { po, oo, gv, cr } = getReducersPipelines.call(this, row)
        return String(Math.abs(((po - oo - gv) / po) * 100))
      }
    )
    showDisbalans.call(this, 'CH', DisbalansValue.Open)
  }

  if (is2pipeScheme(node?.scheme) && isCircScheme(node?.scheme)) {
    this.fillFormula(
      'CI14:CI44',
      'ABS(((D14+M14+V14+Z14+AE14)-(H14+Q14+AI14)-(BD14+BL14+BT14)+(BH14+BP14+BX14))/(D14+M14+V14+Z14+AE14)*100)',
      (row, col) => {
        const { po, oo, gv, cr } = getReducersPipelines.call(this, row)
        return String(Math.abs(((po - oo - gv + cr) / po) * 100))
      }
    )
    showDisbalans.call(this, 'CI', DisbalansValue.Circulation)
  }

  if (is3pipeScheme(node?.scheme) || is4pipeScheme(node?.scheme)) {
    this.fillFormula(
      'CG14:CG44',
      'ABS(((D14+M14+V14+Z14+AE14)-(H14+Q14+AI14))/(D14+M14+V14+Z14+AE14)*100)',
      (row, col) => {
        const { po, oo, gv, cr } = getReducersPipelines.call(this, row)
        return String(Math.abs(((po - oo) / po) * 100))
      }
    )
    showDisbalans.call(this, 'CG', DisbalansValue.Close)
  }

  if (isBoilerScheme(node?.scheme)) {
    this.fillFormula('CJ14:CJ44', 'ABS((AN14-AR14)/AN14*100)', (row, col) => {
      const pb = Number(this.getCell('AN' + row).value) || 0
      const ob = Number(this.getCell('AR' + row).value) || 0
      return String(Math.abs(((pb - ob) / pb) * 100))
    })
    showDisbalans.call(this, 'CJ', DisbalansValue.Close)
  }
}

function showDisbalans(
  this: Worksheet,
  col: string,
  disbalansBound: DisbalansValue
) {
  this.getColumn(col).hidden = false
  for (let row = 14; row < 45; row++) {
    const result = this.getCell(col + row)?.value?.result

    if (result && result > disbalansBound) {
      this.pageSetup.printArea = 'A1:' + col + '55'
    }
  }
}

export function hideRowsQotopAndQgvs(
  this: Worksheet,
  { node, summer }: IExcelCardParams
) {
  if (summer || !node?.scheme) return

  if (is2pipeScheme(node?.scheme)) {
    this.getRow(48).hidden = true
    this.getRow(50).hidden = true

    this.getColumn(57).hidden = true //Qgvs
    this.getColumn(65).hidden = true //Qgvs2
    this.getColumn(61).hidden = true //Qcirc
    this.getColumn(69).hidden = true //Qcirc
    this.getColumn(79).hidden = true //Qotop
    this.getColumn(81).hidden = true //Qgv

    this.getCell('BZ47').value = this.getCell('CC47').value
    this.getCell('BZ51').value = this.getCell('CC51').value

    const borderColor = '000'

    this.getCell('BZ47').border = {
      top: { style: 'medium', color: { argb: borderColor } },
      left: { style: 'medium', color: { argb: borderColor } },
      bottom: { style: 'thin', color: { argb: borderColor } },
      right: { style: 'thin', color: { argb: borderColor } },
    }

    this.getCell('BZ51').border = {
      top: { style: 'thin', color: { argb: borderColor } },
      left: { style: 'medium', color: { argb: borderColor } },
      bottom: { style: 'medium', color: { argb: borderColor } },
      right: { style: 'thin', color: { argb: borderColor } },
    }
  }
}

function setConditionalFormattingDisbalans(
  this: Worksheet,
  disbalansValue: DisbalansValue,
  column: IPipelineColumn
) {
  this.addConditionalFormatting({
    ref: getRangeColumn(column),
    rules: [
      {
        type: 'cellIs',
        operator: 'greaterThan',
        formulae: [disbalansValue],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FF00FF00' },
          },
        },
      },
    ],
  })
}

export function formatedDisbalans(this: Worksheet) {
  setConditionalFormattingDisbalans.call(this, DisbalansValue.Close, 'CG')
  setConditionalFormattingDisbalans.call(this, DisbalansValue.Open, 'CH')
  setConditionalFormattingDisbalans.call(this, DisbalansValue.Circulation, 'CI')
}
