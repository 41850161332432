import { compose, ifElse, prop } from 'ramda'
import React from 'react'

import { IRepairDto } from '../../../../api/endpoints.axios/types'
import { Comment } from '../../../../UI'
import * as styles from './Comment.module.scss'

interface Props {
  repair: IRepairDto
}

const getComments = prop('comments')
const getRepairCommentProp = prop('defect')
const getRepairCreatedAt = prop('createdAt')
const getRepairId = prop('id')
const getRepairUser = prop('user')
const getRepairAddress = prop('address')

const getRepairComment = (repair: IRepairDto): IRepairDto['comments'][0] => ({
  id: getRepairId(repair),
  comment: getRepairCommentProp(repair)!,
  createdAt: getRepairCreatedAt(repair),
  user: getRepairUser(repair),
  address: getRepairAddress(repair),
})

const isToday = (date: string) => date === new Date().toLocaleDateString()
const addIcon = (date: string) => `📆  ${date}`

const useDate = () => {
  let prevDate: number, currentDate: number, date: string

  const getDate = (comment) => {
    currentDate = +comment.createdAt

    if (new Date(prevDate).getDate() !== new Date(currentDate).getDate()) {
      date = new Date(comment.createdAt).toLocaleDateString()
      prevDate = +comment.createdAt
    } else {
      return ''
    }

    return date
  }

  return { getDate }
}

const MessageDate = ({ date }: { date: string }) => {
  if (!date) {
    return null
  }

  return (
    <div className={styles.messgaeDate}>
      <span className={styles.label}>{date}</span>
    </div>
  )
}

const CommentsList: React.FC<Props> = ({ repair }) => {
  const { getDate } = useDate()

  const getDateWithIcon = compose(
    addIcon,
    ifElse(
      isToday,
      () => 'Сегодня',
      (date) => date
    ),
    getDate
  )

  return (
    <div className={styles.commentList}>
      {getComments(repair)?.map((comment) => {
        return <Comment key={comment.id} {...comment} />
      })}
    </div>
  )
}

export default CommentsList
