import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

import { client } from './axios'

export const axiosApi = createApi({
  reducerPath: 'axios',
  baseQuery: client(),
  tagTypes: ['RepairsById', 'Repairs', 'RepairById'],
  endpoints: () => ({}),
})

export const grphqlApi = createApi({
  baseQuery: graphqlRequestBaseQuery({
    url: '/api/server/graphql',
  }),
  reducerPath: 'api',
  tagTypes: ['Node', 'Repairs', 'RepairsById', 'RepairById'],
  endpoints: () => ({}),
})
