import './Drawer.scss'

import React, { useCallback, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { Button, Title } from '..'

interface Props {
  content?: JSX.Element
  footer?: JSX.Element
  title: string
  isOpen: boolean
  closeDrawer: () => void
}

const Drawer: React.FC<Props> = ({
  content,
  title,
  isOpen,
  closeDrawer,
  footer,
}) => {
  const wrapperRef = useRef(null)
  const ref: any = wrapperRef.current
  const portal: HTMLElement | null = document.getElementById('drawer')

  const drawer = (
    <div
      className={`Drawer${!isOpen ? ' Drawer--show_false' : ''}`}
      ref={wrapperRef}
    >
      <div className="Drawer__header">
        <Title
          title={title}
          actions={<Button title="✕" onClick={closeDrawer} />}
        />
      </div>

      <div className="Drawer__content">{content}</div>

      <div className="Drawer__footer">{footer}</div>
    </div>
  )

  if (!portal) {
    console.warn(`portal is not founded`)
    return null
  }

  return ReactDOM.createPortal(drawer, portal)
}

export const useDrawer = ({
  content,
  title,
  footer,
}: Pick<Props, 'content' | 'title' | 'footer'>) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const closeDrawer = useCallback(() => setOpen(false), [])
  const openDrawer = useCallback(() => setOpen(true), [])

  const drawer = (
    <Drawer
      title={title}
      content={content}
      footer={footer}
      isOpen={isOpen}
      closeDrawer={closeDrawer}
    />
  )

  return {
    drawer,
    openDrawer,
    isOpen,
    closeDrawer,
  }
}

export default Drawer
