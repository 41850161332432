import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { reportApi } from '../../../api/endpoints.axios/report'

export function useDownloadReport() {
  const dispatch = useDispatch()

  const downloadReport = useCallback(
    async (e: React.SyntheticEvent, reportId: number) => {
      e.preventDefault()
      try {
        const res = await dispatch(
          reportApi.endpoints.downloadReport.initiate({
            reportId: String(reportId),
          })
        )

        if (res.status === 404) {
          alert(await res.json())
          return
        }

        const blob = await res.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = String(reportId)
        document.body.appendChild(a)
        a.click()
        a.remove()
      } catch (e) {
        alert(e)
      }
    },
    [dispatch]
  )

  return { downloadReport }
}
