// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  position: relative;
  z-index: 10;
}
.Title__actions {
  display: flex;
  gap: var(--margin-m);
  justify-content: space-between;
  align-items: center;
}
.Title--bb {
  border-bottom: 1px solid rgb(71.25, 71.25, 71.25);
}
.Title--bt {
  border-top: 1px solid rgb(71.25, 71.25, 71.25);
}`, "",{"version":3,"sources":["webpack://./src/UI/Title/Title.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EAEA,kBAAA;EACA,WAAA;AAFF;AAIE;EACE,aAAA;EACA,oBAAA;EACA,8BAAA;EACA,mBAAA;AAFJ;AAIE;EACE,iDAAA;AAFJ;AAIE;EACE,8CAAA;AAFJ","sourcesContent":["@import '../../scss/const.scss';\n\n.Title {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  box-sizing: border-box;\n  width: 100%;\n  height: 50px;\n  line-height: 50px;\n  padding: 0 10px;\n  // background: $base-color;\n  position: relative;\n  z-index: 10;\n\n  &__actions {\n    display: flex;\n    gap: var(--margin-m);\n    justify-content: space-between;\n    align-items: center;\n  }\n  &--bb {\n    border-bottom: 1px solid $secondary-color;\n  }\n  &--bt {\n    border-top: 1px solid $secondary-color;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
