import './Comment.scss'

import clsx from 'clsx'
import React from 'react'

import { Comment as IComment } from '../../models'
import { useAuthService } from '../../services/auth.service'

interface Props extends IComment {
  status?: number
  actions?: JSX.Element
  replyTo?: Omit<Props, 'replyTo' | 'actions'>
}

const Comment: React.FC<Props> = ({ status, actions, replyTo, ...comment }) => {
  const { isCurrenUser } = useAuthService()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: isCurrenUser(comment.user) ? 'flex-end' : 'start',
        // opacity: disabled ? 0.5 : 1,
      }}
      className={clsx({
        MyComment: isCurrenUser(comment.user),
        AlienComment: !isCurrenUser(comment.user),
      })}
    >
      <div
        className={clsx({
          Comment: true,
          Comment__bb_green: status !== 1,
          Comment__bb_red: status === 1,
        })}
      >
        <div className="Comment__content">
          <b>{comment.user?.profile?.name}</b>
          {replyTo && (
            <div className="Comment__ReplyTo">
              <b>{replyTo?.user?.profile?.name}</b>
              <p>{replyTo?.comment}</p>
            </div>
          )}
          <p>{comment.comment}</p>
        </div>
        <div className="Comment__Time">
          <b>{new Date(comment.createdAt).toLocaleDateString()}</b>
        </div>
        {actions && <div className="Comment__Actions">{actions}</div>}
      </div>
    </div>
  )
}

export default Comment
