import './Tab.scss'

import React, { useEffect, useState } from 'react'

import Title from '../Title'

interface Props {
  tabs: Tab[]
  action?: (index: number) => void
  defaultTab?: number
}
interface Tab {
  name: string
  content: JSX.Element | JSX.Element[]
  id: string
}

const Tab: React.FC<Props> = ({ tabs, action, defaultTab }) => {
  const [tabIndex, setTabIndex] = useState<number>(defaultTab || 0)

  useEffect(() => {
    const cursor = document.getElementById('cursor')

    if (cursor) {
      const tabItems = document.getElementsByClassName('Tab__Button')
      const tabItem = tabItems[tabIndex] as HTMLElement
      cursor.style.left = tabItem.offsetLeft + 'px'
      cursor.style.width = tabItem.clientWidth + 'px'
    }
  }, [tabIndex])

  const changeTab = (index: number, e: any) => {
    setTabIndex(index)
    // action(index)
  }

  return (
    <div className="Tab">
      <Title>
        <ul className="Tab__Nav">
          {tabs.map((tab, index) => {
            return (
              <li
                key={tab.id}
                className={`Tab__Button${
                  tabIndex === index ? ' Tab__Button--active' : ''
                }`}
                onClick={(e) => changeTab(index, e)}
              >
                {tab.name}
              </li>
            )
          })}
          <span className="Tab__Cursor" id="cursor"></span>
        </ul>
      </Title>
      <div className="Tab__Content">{tabs[tabIndex].content}</div>
    </div>
  )
}

export default Tab
