// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppFooter {
  grid-area: footer;
  width: 100%;
  background: rgb(45.75, 45.75, 45.75);
  border-top: 1px solid rgb(58.5, 58.5, 58.5);
  padding: 0 10px;
}
.AppFooter .List__content--inline {
  margin: 0;
  justify-content: space-around;
  width: inherit;
}
.AppFooter .List__content--inline .List__link {
  flex-grow: 1;
  margin: 0;
}
.AppFooter .List__content--inline .ListItem__content {
  width: 100%;
  text-align: center;
}
.AppFooter .List__content--inline .active {
  color: orange;
}`, "",{"version":3,"sources":["webpack://./src/UI/App/Footer/Footer.scss","webpack://./src/scss/const.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,WAAA;EACA,oCAAA;EACA,2CC8BO;ED7BP,eAAA;AAAF;AACE;EACE,SAAA;EACA,6BAAA;EACA,cAAA;AACJ;AAAI;EACE,YAAA;EACA,SAAA;AAEN;AACI;EACE,WAAA;EACA,kBAAA;AACN;AAEI;EACE,aAAA;AAAN","sourcesContent":["@import '../../../scss/const.scss';\n.AppFooter {\n  grid-area: footer;\n  width: 100%;\n  background: darken($secondary-color, 10%);\n  border-top: $border;\n  padding: 0 10px;\n  .List__content--inline {\n    margin: 0;\n    justify-content: space-around;\n    width: inherit;\n    .List__link {\n      flex-grow: 1;\n      margin: 0;\n    }\n\n    .ListItem__content {\n      width: 100%;\n      text-align: center;\n    }\n\n    .active {\n      color: orange;\n    }\n  }\n}\n","$theme: dark;\n$min-height: 50px;\n$title-height: 50px;\n\n$base-color: #212121;\n$active-color: lighten($base-color, 10%);\n$hover-color: lighten($base-color, 20%);\n\n$secondary-color: lighten($base-color, 15%);\n\n$accent-color: lighten($base-color, 15%);\n\n// $red: #c6538c;\n$red: #e12526;\n$font-color: #dadada;\n$font-color--active: darken($font-color, 10%);\n$font-color--hover: darken($font-color, 5%);\n//-------------\n// $base-color: #fff;\n// $active-color: #F4F5F7;\n// $hover-color: #EBECF0;\n\n// $secondary-color: rgb(244, 245, 247);\n// $accent-color: darken($base-color, 15%);\n\n// $font-color: #172B4D;\n// $font-color--active: #0053cc;\n// $font-color--hover: #172B4D;\n//--------\n$bckClr: $base-color;\n\n$footer-height: 22px;\n\n$mobile: 'max-width: 425px';\n$mobileMenu-height: 50px;\n$border: 1px solid $active-color;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
