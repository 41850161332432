import { format } from 'date-fns'
import React from 'react'

import * as styles from './Footer.module.scss'
export const Footer = () => {
  return (
    <div className={styles.footer}>
      <p>{format(new Date(), 'yyyy')}</p>
    </div>
  )
}
