import { join, partial, path, prop } from 'ramda'
import React, { useMemo } from 'react'

import { Node } from '../../models'
import { Section } from '../../UI'
import * as styles from './Info.module.scss'

export interface IInfoProps {
  node: Node
  loading: boolean
}
const getNodeParam = (pref: string, suf: string, value: string | number) =>
  `${pref} = ${value} ${suf}`

const getQot = partial(getNodeParam, ['Qот', 'Гкал/ч'])
const getGgvs = partial(getNodeParam, ['Gгвс', 'т/ч'])
const getThu = partial(getNodeParam, ['Tхи', '°C'])
const getParamsString = join(' | ')
const getConsumer = path<string>(['consumer', 'name'])
const getProvider = path<string>(['provider', 'name'])
const getContract = prop<string>('contract')

const Info: React.FC<IInfoProps> = ({ node, loading }) => {
  const scheme = prop('scheme', node)

  const nodeParams = useMemo(
    () =>
      getParamsString([
        getQot(scheme?.heatLoad1),
        getGgvs(scheme?.heatLoad2),
        getThu(scheme?.ths),
      ]),
    [scheme?.heatLoad1, scheme?.heatLoad2, scheme?.ths]
  )

  return (
    <Section>
      <div className={styles.info}>
        <div style={{ display: 'flex', justifyContent: 'start', gap: '30px' }}>
          <InfoItem title="Поставщик" value={getProvider(node)} />
          <InfoItem title="Потребитель" value={getConsumer(node)} />
          <InfoItem title="Договор" value={getContract(node)} />
          <InfoItem title="Нагузки" value={nodeParams} />
        </div>
        <InfoItem title="Схема" value={scheme?.scheme} />
        <InfoItem title="Формула зима" value={scheme?.formulaWin} />
        <InfoItem title="Формула лето" value={scheme?.formulaSum} />
      </div>
    </Section>
  )
}

const InfoItem = ({ title, value }: { title: string; value?: string }) => {
  return (
    <div className={styles.item}>
      <p className={styles.title}>{title}:</p>
      {value && <p title={value}>{value}</p>}
    </div>
  )
}

export default Info
