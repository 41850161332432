import './Chat.scss'

import * as React from 'react'
import { Field, Form } from 'react-final-form'

import { Button } from '../../UI'

interface Prop<T, V> {
  onSubmit: (values: V) => void
  initialValues: T
  loading: boolean
  children: JSX.Element
  name: string
  placeholder: string
}
type IChat = <T, V>(props: Prop<T, V>) => React.ReactElement<Prop<T, V>>

const Chat: IChat = ({
  children,
  onSubmit,
  initialValues,
  loading,
  name,
  placeholder,
}) => {
  React.useEffect(() => {
    const messages = document.getElementsByClassName('Comment')
    const lastMessage = messages[messages.length - 1]

    if (lastMessage) {
      lastMessage.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
  })

  return (
    <div className="Chat">
      <div className="Chat__Form">
        <div className="Chat__Message">{children}</div>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit }) => (
            <form className="Form Form--inline" onSubmit={handleSubmit}>
              <Field
                component="input"
                type="text"
                className="Input"
                name={name}
                placeholder={placeholder}
              />

              <Button title={'✈️'} loading={loading} />
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

export default Chat
