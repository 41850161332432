import './Button.scss'

import React, { SyntheticEvent } from 'react'

import { Loader } from '..'

interface Props {
  title: string
  onClick?: (e: SyntheticEvent) => any
  loading?: boolean
  style?: any
  icon?: string
  disabled?: boolean
  round?: boolean
}

const Button: React.FC<Props> = ({
  title,
  loading,
  onClick,
  style,
  icon,
  disabled,
  round,
}) => {
  const LOADING_STYLE = {
    opacity: loading ? 0 : 1,
  }

  if (icon) {
    return (
      <button
        className={`Button Button--Icon${round ? ' Button--Round' : ''}`}
        onClick={onClick}
        disabled={disabled || loading}
        title={title}
      >
        <span style={{ position: 'absolute' }}>
          {loading && <Loader size={'S'} />}
        </span>
        <span style={LOADING_STYLE}>
          <span style={{ paddingRight: 5 }}>{icon}</span> {!round && title}
        </span>
      </button>
    )
  }

  return (
    <button
      className={`Button${round ? ' Button--Round' : ''}`}
      onClick={onClick}
      disabled={disabled || loading}
      style={{ ...style, position: 'relative' }}
      title={title}
    >
      <span style={{ position: 'absolute' }}>
        {loading && <Loader size={'S'} />}
      </span>
      <span style={LOADING_STYLE}>{title}</span>
    </button>
  )
}

export default Button
