// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Content {
  position: relative;
  grid-area: content;
}`, "",{"version":3,"sources":["webpack://./src/UI/App/Content/Content.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;AACF","sourcesContent":[".Content {\n  position: relative;\n  grid-area: content;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
