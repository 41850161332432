// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 425px;
  margin: 0 auto;
}
.Login .Content {
  display: grid;
  grid-template-areas: "logo title" "logo form";
  grid-template-rows: 1fr auto auto 1fr;
  grid-template-columns: auto auto;
}
.Login__title {
  grid-area: title;
}
.Login__form {
  grid-area: form;
}
.Login__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: logo;
}
@media (max-width: 425px) {
  .Login .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .Login__form {
    grid-area: form;
    width: 100%;
    max-width: 425px;
    padding: 0 20px 0px;
  }
}

.Page.Login {
  display: block;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: 25% 75%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EAEA,gBAAA;EACA,cAAA;AAAF;AAEE;EACE,aAAA;EACA,6CAAA;EACA,qCAAA;EACA,gCAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAGE;EACE,eAAA;AADJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AADJ;AAIE;EACE;IACE,aAAA;IACA,sBAAA;IACA,mBAAA;IACA,uBAAA;IACA,aAAA;EAFJ;EAIE;IACE,eAAA;IACA,WAAA;IACA,gBAAA;IACA,mBAAA;EAFJ;AACF;;AAKA;EACE,cAAA;EACA,yDAAA;EACA,4BAAA;AAFF","sourcesContent":[".Login {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 100%);\n  max-width: 425px;\n  margin: 0 auto;\n\n  .Content {\n    display: grid;\n    grid-template-areas: 'logo title' 'logo form';\n    grid-template-rows: 1fr auto auto 1fr;\n    grid-template-columns: auto auto;\n  }\n\n  &__title {\n    grid-area: title;\n  }\n  &__form {\n    grid-area: form;\n  }\n  &__logo {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    grid-area: logo;\n  }\n\n  @media (max-width: 425px) {\n    .Content {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      height: 100vh;\n    }\n    &__form {\n      grid-area: form;\n      width: 100%;\n      max-width: 425px;\n      padding: 0 20px 0px;\n    }\n  }\n}\n.Page.Login {\n  display: block;\n  background-image: url('../../img/logo.svg');\n  background-position: 25% 75%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
